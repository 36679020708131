export default class CurrencyUtils {
  constructor() {
    this.currencyMap = {
      inr: "₹",
    }
  }

  // FIXME: need to fix this method
  static getCurrency(currency) {
    console.log("this.currencyMap", this.currencyMap)
    return "₹"
    // return this.currencyMap[currency]
  }
}
