import React, { useState } from "react"
import { view } from "react-easy-state"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Input, Button, Select, Form } from "antd"

const PricingForm = ({ type, onSubmitPricingForm, hidePricingForm }) => {
  const [form] = Form.useForm()
  const [selectedCurrency, setSelectedCurrency] = useState(null)

  const handleFinish = (values) => {
    if (selectedCurrency) {
      values = { ...values, currency: selectedCurrency }
    }

    onSubmitPricingForm(type, values)
    form.resetFields()
  }

  const currencyDropdown = (
    <Select defaultValue="INR" style={{ width: 80 }} onChange={setSelectedCurrency}>
      <Select.Option value="USD">USD</Select.Option>
      <Select.Option value="INR">INR</Select.Option>
      <Select.Option value="AUD">AUD</Select.Option>
    </Select>
  )

  return (
    <div className="pricing-form form-card">
      <div className="title">
        <Button shape="circle" onClick={hidePricingForm}>
          <ArrowLeftOutlined />
        </Button>
        <h2>{type === "free" ? "Free" : "One-Time Purchase"}</h2>
      </div>
      <Form form={form} onFinish={handleFinish}>
        {type === "one-time" && (
          <Form.Item label="Price" name="price" rules={[{ required: true, message: "Please enter price!" }]}>
            <Input size="large" placeholder="e.g. '100'" addonBefore={currencyDropdown} addonAfter={".00"} />
          </Form.Item>
        )}
        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter name!" }]}>
          <Input size="large" placeholder={type === "free" ? "e.g. 'Free Course'" : "e.g. 'Paid Course'"} />
        </Form.Item>
        <Form.Item label="Subtitle" name="subtitle" rules={[[{ required: true, message: "Please enter name!" }]]}>
          <Input
            size="large"
            placeholder={
              type === "free" ? "e.g. 'Free course for all'" : "e.g. 'Hurry up !!, Only $100 for this course'"
            }
          />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Please enter description!" }]}
        >
          <Input.TextArea rows={4} placeholder="e.g. 'We are celebrating 10th opening of this online school...'" />
        </Form.Item>
        <div style={{ width: "inherit" }}>
          <Button type="primary" size="large" htmlType="submit" style={{ width: "100%" }}>
            Add Pricing
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default view(PricingForm)
