import React from "react"
import { view } from "react-easy-state"
import { DeleteOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Button, Tooltip } from "antd";

import YoutubePlayer from "components/VideoPlayer/Youtube"

const YoutubeVideoLectureContent = ({ url, deleting, onDelete }) => {
  return (
    <div className="form-card">
      <div className="asset-header">
        <h3>
          <VideoCameraOutlined /> Video
        </h3>
        <div>
          <div>
            <Tooltip title="Delete attachment">
              <Button type="danger" shape="circle" onClick={onDelete} loading={deleting}>
                {!deleting && <DeleteOutlined />}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div>
        <YoutubePlayer url={url} />
      </div>
    </div>
  )
}

export default view(YoutubeVideoLectureContent)
