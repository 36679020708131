import React from "react"
import { view } from "react-easy-state"
import Editor from "utils/grapesjs/Editor"
import { getAuthorization } from "api"

import "grapesjs/dist/css/grapes.min.css"

const EditorPage = (props) => {
  return (
    <div style={{ width: "100%" }}>
      <Editor
        presetType="webpage"
        plugins={[]}
        storageManager={{
          type: "remote",
          stepsBeforeSave: 3,
          urlLoad: `${process.env.REACT_APP_API_BASE_URL}/api/school-admin/landing-pages/${1}/load`,
          urlStore: `${process.env.REACT_APP_API_BASE_URL}/api/school-admin/landing-pages/${1}/store`,
          headers: { Authorization: getAuthorization() },
          credentials: "omit",
        }}
      />
    </div>
  )
}

export default view(EditorPage)
