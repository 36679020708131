import React from "react"
import { view } from "react-easy-state"
import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons';
import { Avatar, Button, Tooltip } from "antd";

const FileLectureContent = ({ attachedFile, onDelete, deleting }) => {
  const renderFile = (file) => {
    return (
      <div className="lecture-show attached-files">
        {file.contentType.includes("image") && (
          <div className="attached-files_image">
            <div>
              <Avatar style={{ backgroundColor: "#b9b9b9" }} shape="square" size={40} src={file.url} />
              <Button type="link" href={file.url} target="_blank">
                {file.file_name}
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }

  if(!attachedFile) return null

  return (
    <div className="form-card">
      <div className="asset-header">
        <h3>
          <FileTextOutlined /> File
        </h3>
        <div>
          <div>
            <Tooltip title="Delete attachment">
              <Button type="danger" shape="circle" onClick={onDelete} loading={deleting}>
                {!deleting && <DeleteOutlined />}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div>{renderFile(attachedFile)}</div>
    </div>
  )
}

export default view(FileLectureContent)
