import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { view } from 'react-easy-state'
import { isMobile } from 'react-device-detect'

import Routes from 'routes'
import ErrorBoundary from 'components/common/ErrorBoundary'

import 'antd/dist/antd.less'
import './App.css'

const sections = [{
  id: 1,
  name: 'New Section',
  lectures: [{
    id: 1001,
    name: 'Lecture 1'
  }]
}, {
  id: 2,
  name: 'New Section 2',
  lectures: [{
    id: 1002,
    name: 'Lecture 1'
  }, {
    id: 1003,
    name: 'Lecture 2'
  }]
}, {
  id: 3,
  name: 'New Section 3',
  lectures: []
}]

window.localStorage.setItem('sections', JSON.stringify(sections))

function App() {
  return (
    <div className={isMobile ? "mobile-view" : ""}>
      <ErrorBoundary>
        <Router>
          <Routes />
        </Router>
      </ErrorBoundary>
    </div>
  )
}

export default view(App)
