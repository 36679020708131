import React, { useState } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { InboxOutlined } from "@ant-design/icons"
import { Upload, message, Button } from "antd"

import globalStore from "store/index"
import ContentsService from "services/admin/contents.service"

const StyledWrapper = styled.div`
  padding: 20px 0px;
  & > button {
    margin-top: 20px;
  }
`

const NewLectureAddFile = ({ match }) => {
  const { lectureId } = match.params
  const pageState = globalStore.ui.lectures.show
  const [fileList, setFileList] = useState([])
  const [submitting, setSubmitting] = useState(false)

  const uploadProps = {
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)

      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      if (!file || file.type.includes("video")) {
        message.info("Video files are not allowed to be uploaded.")
        return
      }

      setFileList([...fileList, file])
      return false
    },
    fileList,
  }

  const uploadFiles = async () => {
    const formData = new FormData()

    fileList.forEach((file) => {
      formData.append("content[file]", file)
    })

    formData.append("content[content_type]", "file")

    setSubmitting(true)

    await ContentsService.create({ pageState, lectureId, formData })

    setSubmitting(false)
    message.destroy()

    if (pageState.lectureErrors) {
      message.error("Failed to add file, please try again")
      return
    }

    message.success("File added successfully.")
    setFileList([])
  }

  return (
    <StyledWrapper>
      <Upload.Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload (Non-video files only)</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
        </p>
      </Upload.Dragger>
      <Button type="primary" onClick={uploadFiles} style={{ marginTop: 30 }} loading={submitting} block>
        {submitting ? "Uploading..." : "Upload"}
      </Button>
    </StyledWrapper>
  )
}

export default withRouter(view(NewLectureAddFile))
