import React, { useEffect } from "react"
import { view } from "react-easy-state"
import { Route, Switch, withRouter } from "react-router-dom"
import queryString from "query-string"

// import Sider from "components/common/layouts/Sider"
import Content from "components/common/layouts/Content"

// import DashboardPage from "pages/Dashboard"
// import HomePage from "pages/Home"
import CoursesPage from "pages/school-admin/courses/Index"
import NewCoursePage from "pages/school-admin/courses/New"
import CourseInformationPage from "pages/school-admin/courses/Information"
import CourseCurriculumPage from "pages/school-admin/courses/Curriculum"
import CoursePricingPage from "pages/school-admin/courses/Pricing"
import CourseCommentsPage from "pages/school-admin/courses/Comments"
import CourseBundlePage from "pages/school-admin/courses/Bundle"
import NewLecturePage from "pages/school-admin/courses/NewLecture"
import NewSectionPage from "pages/school-admin/courses/NewSection"
// import RegisterPage from "pages/Register"
import LoginPage from "pages/users/Login"
import LogoutPage from "pages/users/Logout"
import Error404Page from "pages/Error404"
import AdminSchoolPlanPage from "pages/school-admin/Plan/Index"
import LandingPagesPage from "pages/school-admin/LandingPage/Index"
import LandingPagePage from "pages/school-admin/LandingPage/Show"

import ProfilePage from "pages/users/Profile"
import ProfileEditPage from "pages/users/ProfileEdit"

import StudentCoursesIndexPage from "pages/student/courses/Index"
import StudentAllCoursesPage from "pages/student/courses/All"
import StudentCoursesShowPage from "pages/student/courses/Show"
// import SchoolCoursesIntructorPage from "pages/school/courses/Instructor"
import StudentLecturesShowPage from "pages/student/lectures/Show"
import StudentRegisterPage from "pages/users/Register"

import EditorPage from "pages/Editor"
import PrivacyPage from "pages/Privacy"
import CancellationPage from "pages/Cancellation"
import TermsPage from "pages/Terms"

// import GuestCoursesIndexPage from "pages/guest/courses/Index"

import PrivateRoute from "routes/PrivateRoute"

import * as AuthHelper from "helpers/auth.helper"

const withAppLayout = (WrappedComponent) => (props) => (
  <Content>
    <WrappedComponent {...props} />
  </Content>
)

export const AdminPrivateRoute = ({ component, withoutAppLayout, ...props }) => {
  if (withoutAppLayout) return <PrivateRoute loginRoute="/login" component={component} {...props} />
  return <PrivateRoute loginRoute="/login" component={withAppLayout(component)} {...props} />
}
// const UserPrivateRoute = (props) => <PrivateRoute loginRoute="/user/login" {...props} />

const redirectOnToken = ({ location, history, currentPath }) => {
  const params = queryString.parse(location.search)

  if (!params) return
  if (!params.token) return

  AuthHelper.setToken(params.token)
  history.replace(currentPath)
}

const Routes = ({ location, history }) => {
  const currentPath = location.pathname

  redirectOnToken({ location, history, currentPath })
  // If token is received, store the token and replace the history url with a non-token one
  // useEffect(() => {
  //   if (!params) return
  //   if (!params.token) return

  //   AuthHelper.setToken(params.token)
  //   // console.log("params.token", params.token)
  //   // console.log("token set to", AuthHelper.getToken())
  //   history.replace(currentPath)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [params, currentPath])

  // If a user logs in on "app" subdomain, redirect to profile
  useEffect(() => {
    // FIXME: We will have to set an array for this
    if (!window.location.host.startsWith("app.")) return
    if (window.location.pathname !== "/") return
    if (!AuthHelper.isLoggedIn()) return

    history.replace("/profile")
  })

  // const adminPathWithoutSider = []
  // const showSider = currentPath.startsWith("/admin") && !adminPathWithoutSider.includes(currentPath)

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Route path="/">
        {/* {!withoutAppLayoutPagePaths.includes(currentPath) && <Sider />} */}
        {/* {showSider && <Sider />} */}
        <Switch>
          {/* <AdminPrivateRoute exact path="/admin" component={DashboardPage} /> */}

          <AdminPrivateRoute exact path="/admin" component={CoursesPage} />
          <AdminPrivateRoute exact path="/admin/courses" component={CoursesPage} />
          <AdminPrivateRoute exact path="/admin/courses/new" component={NewCoursePage} />
          <AdminPrivateRoute exact path="/admin/courses/:courseId/information" component={CourseInformationPage} />
          <AdminPrivateRoute exact path="/admin/courses/:courseId/curriculum" component={CourseCurriculumPage} />
          <AdminPrivateRoute
            exact
            path="/admin/courses/:courseId/curriculum/lectures/:lectureId"
            component={NewLecturePage}
          />
          <AdminPrivateRoute exact path="/admin/courses/:courseId/curriculum/section/new" component={NewSectionPage} />
          <AdminPrivateRoute exact path="/admin/courses/:courseId/pricing" component={CoursePricingPage} />
          <AdminPrivateRoute exact path="/admin/courses/:courseId/comments" component={CourseCommentsPage} />
          <AdminPrivateRoute exact path="/admin/courses/:courseId/bundle" component={CourseBundlePage} />
          <AdminPrivateRoute exact path="/admin/settings/plan" component={AdminSchoolPlanPage} />
          <AdminPrivateRoute path="/admin/landing-pages/:landingPageId" withoutAppLayout component={LandingPagePage} />
          <AdminPrivateRoute path="/admin/landing-pages" component={LandingPagesPage} />

          <Route exact path="/courses/enrolled" component={StudentCoursesIndexPage} />
          <Route exact path="/courses" component={StudentAllCoursesPage} />

          <Route exact path="/courses/:courseId/" component={StudentCoursesShowPage} />
          {/* <Route exact path="/courses/:courseId/instructor" component={SchoolCoursesIntructorPage} /> */}
          <Route exact path="/courses/:courseId/lectures/:lectureId" component={StudentLecturesShowPage} />

          {/* <Route exact path="/admin/register" component={RegisterPage} /> */}
          <Route exact path="/register" component={StudentRegisterPage} />
          {/* <Route exact path="/admin/login" component={LoginPage} /> */}
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/logout" component={LogoutPage} />

          {/* TODO: Make this route private */}
          <AdminPrivateRoute path="/profile/edit" withoutAppLayout component={ProfileEditPage} />
          <AdminPrivateRoute path="/profile" withoutAppLayout component={ProfilePage} />

          <Route exact path="/editor" component={EditorPage} />
          <Route exact path="/privacy-policy" component={PrivacyPage} />
          <Route exact path="/cancellation-refund-policy" component={CancellationPage} />
          <Route exact path="/terms-and-conditions" component={TermsPage} />
          {/* <Route exact path="/guest/courses" component={GuestCoursesIndexPage} /> */}

          <Route exact path="/" component={StudentAllCoursesPage} />
          <Route path="/" component={Error404Page} />
        </Switch>
      </Route>
    </div>
  )
}

export default withRouter(view(Routes))
