import React, { useState } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import BraftEditor from "braft-editor"
import styled from "styled-components"
import { Button, message } from "antd"

import globalStore from "store"
import ContentsService from "services/admin/contents.service"

import "braft-editor/dist/index.css"

const StyledWrapper = styled.div`
  padding: 20px 0px;
  & > button {
    margin-top: 20px;
  }
`

const braftEditorState = BraftEditor.createEditorState("<p>Hello <b>World!</b></p>")

const NewLectureAddRichText = ({ match }) => {
  const pageState = globalStore.ui.lectures.show
  const { lectureId } = match.params

  const [editorState, setEditorState] = useState(braftEditorState)
  const [outputHTML, setOutputHTML] = useState("<p></p>")
  const [submitting, setSubmitting] = useState(false)

  const handleChange = (editorState) => {
    setEditorState(editorState)
    setOutputHTML(editorState.toHTML())
  }

  const saveRichText = async () => {
    const postData = {
      content: {
        details: {
          html: outputHTML.toString(),
        },
        content_type: "note",
      },
    }

    setSubmitting(true)

    await ContentsService.create({ pageState, lectureId, values: postData })

    setSubmitting(false)

    if (pageState.lectureErrors) {
      message.error("Failed to add note, please try again")
      return
    }

    setEditorState(braftEditorState)
    message.success("Note added successfully.")
  }

  return (
    <StyledWrapper>
      <BraftEditor language="en" value={editorState} onChange={handleChange} className="braft-editor-main" />
      <div className="save-button">
        <Button type="primary" size="large" onClick={saveRichText} loading={submitting}>
          {submitting ? "Saving..." : "Save"}
        </Button>
      </div>
    </StyledWrapper>
  )
}

export default withRouter(view(NewLectureAddRichText))
