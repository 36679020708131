import { API, generic } from "api"

export default class PlansService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_CM_PLANS_INDEX_STATUS",
      stateDataKey: ["plans", "current_plan"],
      stateErrorKey: ["plansErrors", "currentPlanErrors"],
      apiEndpoint: API.admin.cm.plans.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Plans could not be fetched",
    })
  }
}
