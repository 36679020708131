import React, { useState, useEffect } from "react"
import { view } from "react-easy-state"
import styled from "styled-components"
import { withRouter, Route } from "react-router-dom"
import { Icon as LegacyIcon } from "@ant-design/compatible"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Button, Avatar, message } from "antd"

import { Color, BoxShadow } from "constant/theme"

import ContentLoading from "components/ContentLoading/Index"
import ContentLoadingError from "components/ContentLoadingError/Index"
import SchoolCardList from "components/SchoolCardList/Index"
import CreateSchoolForm from "components/pages/users/profile/CreateSchoolForm"

import globalStore from "store"

import UsersService from "services/users/users.service"
import SchoolsService from "services/admin/schools.service"

import * as URLHelper from "helpers/url.helper"

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`

const StyledPageSider = styled.div`
  position: fixed;
  width: 280px;
  left: 20px;
  top: 20px;
  min-height: calc(100vh - 40px);
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
`

const StyledMainContent = styled.div`
  position: relative;
  width: calc(100vw - 380px); //Total padding(20*3) + Sider width(280)
  left: 360px;
  top: 20px;
  & > div.container {
    width: 100%;
    position: absolute;
    top: 60px;
    min-height: calc(100vh - 40px);
    border-radius: 7px;
  }
`

const StyledLink = styled.div`
  position: relative;
  & > button {
    padding: 0px;
    color: white;
    &:hover {
      color: white;
    }
  }
`

const StyledSiderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  position: absolute;
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
  color: ${Color.brandColor};
  & > .logo {
    padding: 20px 0px;
  }
  & > .ant-avatar {
    margin: 20px 0px 20px 0px;
  }
  & > h2,
  & > p {
    font-size: 0.8em;
    color: black;
    font-weight: normal;
  }
`
const StyledSiderMenu = styled.ul`
  margin: 30px 0px 0px 0px;
  padding: 0px;
  list-style-type: none;
  width: 100%;
  & > li {
    margin-bottom: -1px;
    border: 1px solid whitesmoke;
    & > button {
      height: 40px;
      color: black;
      text-align: left;
    }
  }
`

const StyledEditProfileLink = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
`

const ProfilePage = ({ history }) => {
  const pageState = globalStore.ui.users.profile

  const menuItems = [
    {
      label: "My Schools",
      path: "/profile",
      icon: "bank",
    },
    {
      label: "Create New School",
      path: "/profile/schools/new",
      icon: "plus-circle",
    },
    {
      label: "Logout",
      onClick: () => history.push("/logout"),
      icon: "poweroff",
    },
  ]

  const [dataFetched, setDataFetched] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false)

  useEffect(() => {
    const fetchPageData = async () => {
      const pageState = globalStore.ui.users.profile
      await UsersService.me({ pageState })

      globalStore.currentUser.user = JSON.parse(JSON.stringify(pageState.user))
    }
    if (!dataFetched) {
      fetchPageData()
      setDataFetched(true)
    }
  }, [dataFetched])

  const afterCreateSchoolSubmit = () => {
    setDataFetched(false)
  }

  const handleUpload = async ({ fileList, schoolId }) => {
    if (!fileList || !schoolId) return
    if (fileList.length !== 1) return

    setUploading(true)
    setUploadSuccess(false)

    const formData = new FormData()

    formData.append("file", fileList[0])

    await SchoolsService.uploadDisplayPicture({ pageState, schoolId, formData })

    setUploading(false)

    const { schoolErrors } = pageState

    if (schoolErrors) {
      message.error("Failed to update school's display picture")
      return
    }

    message.success("Schoo picture updated successfully.")

    setUploadSuccess(true)
  }

  const renderSider = () => {
    const { user } = pageState

    if (!user) return <ContentLoading />

    const menuItemOnClick = (menuItem) => {
      if (menuItem.onClick) return menuItem.onClick
      if (menuItem.path) return () => history.push(`${menuItem.path}`)
    }

    return (
      <StyledSiderContent>
        <div className="logo">CourseMango</div>
        <Avatar shape="square" size={80} src={user.display_picture_url} />
        <h2>{user.name}</h2>
        <p>{user.email}</p>
        <StyledSiderMenu>
          {menuItems.map((mi, index) => (
            <li key={index}>
              <Button type="link" onClick={menuItemOnClick(mi)}>
                <LegacyIcon type={`${mi.icon}`} /> {mi.label}
              </Button>
            </li>
          ))}
        </StyledSiderMenu>
        <StyledEditProfileLink>
          <Button size="large" onClick={() => history.push("/profile/edit")} block>
            Edit Profile
          </Button>
        </StyledEditProfileLink>
      </StyledSiderContent>
    )
  }

  const renderOwnedSchools = () => {
    const { user } = pageState

    if (!user) return null
    if (!user.schools_as_owner) return null
    if (user.schools_as_owner.length === 0) {
      if (URLHelper.isSecureSubDomain(window.location.origin)) {
        return <CreateSchoolForm pageState={pageState} afterSubmit={afterCreateSchoolSubmit} />
      }
      return null
    }
    return (
      <SchoolCardList
        schools={user.schools_as_owner}
        owned
        title="Schools I Own"
        onUpload={handleUpload}
        uploading={uploading}
        uploadSuccess={uploadSuccess}
      />
    )
  }

  const renderEnrolledSchools = () => {
    const { user } = pageState
    if (!user) return null
    if (!user.schools_as_student) return null
    if (user.schools_as_student.length === 0) return null
    return <SchoolCardList schools={user.schools_as_student} title="Schools I've enrolled in" />
  }

  const renderMainContent = () => {
    const { API_USERS_ME_STATUS } = pageState

    if (!API_USERS_ME_STATUS || API_USERS_ME_STATUS === "pending") {
      return <ContentLoading />
    }
    if (API_USERS_ME_STATUS === "rejected") {
      return <ContentLoadingError />
    }

    return (
      <React.Fragment>
        <Route path="/profile/schools/new">
          <CreateSchoolForm
            pageState={pageState}
            afterSubmit={() => {
              afterCreateSchoolSubmit()
              history.push("/profile")
            }}
          />
        </Route>
        <Route exact path="/profile">
          {renderOwnedSchools()}
          {renderEnrolledSchools()}
        </Route>
      </React.Fragment>
    )
  }

  return (
    <StyledPage>
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <StyledPageSider>{renderSider()}</StyledPageSider>
        <StyledMainContent>
          <Row>
            <StyledLink>
              <Button type="link" onClick={() => history.push("/")}>
                <ArrowLeftOutlined /> Go to Home
              </Button>
            </StyledLink>
          </Row>
          <div className="container">{renderMainContent()}</div>
        </StyledMainContent>
      </StyledPageContent>
    </StyledPage>
  )
}

export default withRouter(view(ProfilePage))
