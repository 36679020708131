import React from "react"
import { withRouter } from "react-router-dom"
import { view } from "react-easy-state"

import * as AuthHelper from "helpers/auth.helper"
import * as URLHelper from "helpers/url.helper"
import ContentLoading from "components/ContentLoading/Index"

const LogoutPage = ({ history }) => {
  AuthHelper.logout()

  const referrer = document.referrer

  if (URLHelper.isSecureSubDomain(window.location.href)) {
    if (!referrer) {
      history.replace("/login")
      return
    }

    if (URLHelper.isSecureSubDomain(referrer)) {
      history.replace("/login")
      return
    }

    const parsedReferrer = URLHelper.getParsedUrl(referrer)

    window.location = parsedReferrer.origin
    return
  }

  window.location = URLHelper.getSecureURL("/logout")

  return <ContentLoading />
}

export default withRouter(view(LogoutPage))
