import { API, generic } from "api"

export default class UserLecturesServices {
  static complete = async ({ pageState, lectureId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_USER_LECTURES_COMPLETE_STATUS",
      stateDataKey: ["next_lecture"],
      stateErrorKey: ["nextLectureErrors"],
      apiEndpoint: API.students.user_lectures.complete,
      apiUrlReplacements: { lectureId },
      apiData: {},
      errorMessage: "Lecture could not be updated",
    })
  }
}
