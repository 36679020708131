import axios from "axios"
import { deserialise } from "kitsu-core"
import { message } from "antd"

import { fetchFromLocalStorage } from "store/localStorage"
import ServerNotReachableError from "errors/ServerNotReachableError"

export const getAuthorization = (token) => {
  if (token) return `Bearer ${token}`

  const storedToken = fetchFromLocalStorage({
    key: "token",
  })
  if (!storedToken) return null

  return `Bearer ${storedToken}`
}

export const apiClient = ({ token } = {}) => {
  const headers = {
    "Content-Type": "application/json",
  }

  const authorization = getAuthorization(token)

  if (authorization) {
    headers["Authorization"] = authorization
  }

  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers,
  })
}

export const call = async (f, ...args) => {
  let response
  try {
    response = await f(...args)
  } catch (e) {
    message.destroy()

    Object.keys(e)
      .filter((k) => k !== "toJSON")
      .forEach((k) => console.log(`e.${k}`, e[k]))
    if (!e.isAxiosError) {
      throw e
    }

    response = e.response

    if (!response) {
      throw new ServerNotReachableError("Maybe server is not running")
    }

    console.log("response ---", response)

    // FIXME: handle all server errors here
    if (response.status === 403) {
      // const { errors } = response.data

      // if (typeof errors[0] === "string") {
      //   message.error(errors[0])
      //   return response
      // }

      // message.error("Oops!! something went wrong")
      return response
    }

    if (response.status >= 500) {
      throw e
    }
  }
  return response
}

export const API = {
  users: {
    login: { method: "post", url: "/api/users/login" },
    register: { method: "post", url: "/api/users/register" },
    me: { method: "get", url: "/api/users/me" },
    update: { method: "put", url: "/api/users/me" },
    schools: {
      me: {
        method: "get",
        url: "/api/users/schools/me",
      },
    },
    change_display_picture: { method: "put", url: "/api/users/change-display-picture" },
  },
  admin: {
    me: { method: "get", url: "/api/school-admin/schools/me" },
    school_plans: {
      create: { method: "post", url: "/api/school-admin/courses/:courseId/school_plans" },
      update: { method: "put", url: "/api/school-admin/school_plans/:planId" },
    },
    cm: {
      plans: {
        index: { method: "get", url: "/api/school-admin/cm/plans" },
      },
      subscriptions: {
        create: { method: "post", url: "/api/school-admin/cm/plans/:planId/subscriptions" },
        activate: { method: "put", url: "/api/school-admin/cm/plans/:planId/subscriptions/activate" },
        change_plan: { method: "put", url: "/api/school-admin/cm/plans/:planId/subscriptions/change_plan" },
      },
    },
    schools: {
      create: { method: "post", url: "/api/school-admin/schools" },
      me: { method: "get", url: "/api/school-admin/schools/me" },
      upload_display_picture: {
        method: "put",
        url: "/api/school-admin/schools/:schoolId/upload_display_picture",
      },
      remove_display_picture: {
        method: "put",
        url: "/api/school-admin/schools/:schoolId/remove_display_picture",
      },
    },
    courses: {
      index: { method: "get", url: "/api/school-admin/courses/" },
      show: { method: "get", url: "/api/school-admin/courses/:courseId" },
      create: { method: "post", url: "/api/school-admin/courses/" },
      update: { method: "put", url: "/api/school-admin/courses/:courseId" },
      remove_display_picture: { method: "put", url: "/api/school-admin/courses/:courseId/remove_display_picture" },
      information: { method: "get", url: "/api/school-admin/courses/:courseId/information" },
      publish: { method: "put", url: "/api/school-admin/courses/:courseId/publish" },
      unpublish: { method: "put", url: "/api/school-admin/courses/:courseId/unpublish" },
      curriculum: { method: "get", url: "/api/school-admin/courses/:courseId/curriculum" },
    },
    sections: {
      create: { method: "post", url: "/api/school-admin/courses/:courseId/sections" },
      update: { method: "put", url: "/api/school-admin/sections/:sectionId" },
      reorder: { method: "put", url: "/api/school-admin/sections/:sectionId/reorder" },
    },
    lectures: {
      show: { method: "get", url: "/api/school-admin/lectures/:lectureId" },
      create: { method: "post", url: "/api/school-admin/sections/:sectionId/lectures" },
      update: { method: "put", url: "/api/school-admin/lectures/:lectureId" },
      reorder: { method: "put", url: "/api/school-admin/lectures/:lectureId/reorder" },
      publish: { method: "put", url: "api/school-admin/lectures/:lectureId/publish" },
      unpublish: { method: "put", url: "api/school-admin/lectures/:lectureId/unpublish" },
    },
    contents: {
      // FIXME: the key should match the backend controller#action called.
      // In this case it should be create
      create: { method: "post", url: "/api/school-admin/lectures/:lectureId/contents" },
      delete: { method: "delete", url: "/api/school-admin/contents/:contentId" },
      reorder: { method: "put", url: "/api/school-admin/contents/:contentId/reorder" },
    },
    landing_pages: {
      index: { method: "get", url: "/api/school-admin/landing-pages" },
      show: { method: "get", url: "/api/school-admin/landing-pages/:landingPageId" },
    },
  },
  students: {
    courses: {
      index: { method: "get", url: "/api/students/courses" },
      show: { method: "get", url: "/api/students/courses/:courseId" },
      enroll: { method: "post", url: "/api/students/courses/:courseId/enroll" },
      enrolled: { method: "get", url: "/api/students/courses/enrolled" },
    },
    lectures: { show: { method: "get", url: "/api/students/lectures/:lectureId" } },
    user_lectures: {
      complete: { method: "post", url: "/api/students/lectures/:lectureId/user_lectures" },
    },
    lecture_comments: {
      index: { method: "get", url: "api/students/lectures/:lectureId/lecture_comments?page=:nextPage" },
      create: { method: "post", url: "api/students/lectures/:lectureId/lecture_comments" },
    },
    enrollments: {
      create: { method: "post", url: "/api/students/courses/:courseId/enrollments" },
      razorpay_payment_verification: {
        method: "post",
        url: "/api/students/courses/:courseId/enrollments/razorpay_payment_verification",
      },
    },
  },
  guests: {
    courses: {
      index: { method: "get", url: "/api/guests/courses/" },
    },
  },
}

export const createUrl = (url, replacements = {}) => {
  let createdUrl = url
  Object.keys(replacements).forEach((key) => (createdUrl = createdUrl.replace(`:${key}`, replacements[key])))
  return createdUrl
}

export const generic = async ({
  pageState,
  stateApiStatusKey,
  stateDataKey,
  stateErrorKey,
  apiEndpoint,
  apiUrlReplacements = {},
  apiData = {},
  errorMessage = null,
  serializedResponse = true,
}) => {
  if (!pageState) throw new Error("pageState is required")
  if (!stateApiStatusKey) throw new Error("stateApiStatusKey is required")
  if (!stateDataKey) throw new Error("stateDataKey is required")
  if (!stateErrorKey) throw new Error("stateErrorKey is required")
  if (!apiEndpoint) throw new Error("apiEndpoint is required")
  if (!apiEndpoint.method) throw new Error("apiEndpoint.method is required")
  if (!apiEndpoint.url) throw new Error("apiEndpoint.url is required")

  const { method, url: urlRaw } = apiEndpoint
  let response
  try {
    pageState[stateApiStatusKey] = "pending"
    const url = createUrl(urlRaw, apiUrlReplacements)
    response = await call(apiClient()[method], url, apiData)
  } catch (e) {
    pageState[stateApiStatusKey] = "rejected"
    if (e.name === "ServerNotReachableError") {
      pageState.serverNotReachable = true
      message.error("Unable to reach server, please try after some time")
      return
    }
    message.error("An error occured, please try after some time")
    throw e
  }

  // TODO: This needs to be improved
  // the variables are restrictive
  // code is not DRY

  pageState["serverStatus"] = {
    status: response.status,
    statusText: response.statusText,
  }

  if (!serializedResponse) {
    pageState[stateApiStatusKey] = "fulfilled"
    pageState[stateDataKey] = response.data.data
    return
  }
  if (response.data && response.data.multi_data) {
    if (!(stateDataKey instanceof Array)) throw new Error("stateDataKey should be an array for multi-data response")
    if (!(stateErrorKey instanceof Array)) throw new Error("stateErrorKey should be an array for multi-data response")
    if (stateDataKey.length !== stateErrorKey.length)
      throw new Error("stateErrorKey and stateErrorKey should have the same length")

    const promises = stateDataKey.map(async (k, index) => {
      console.log("k", k)
      console.log("response.data[k]", response.data[k])
      if (!response.data[k]) return

      const deserialisedData = await deserialise(response.data[k])

      if (deserialisedData.errors) {
        // TODO: Check if the following is required
        // if (method !== 'put') {
        //   delete pageState[stateDataKey]
        // }
        pageState[stateErrorKey[index]] = deserialisedData.errors
        return
      }
      console.log("deserialisedData", deserialisedData)
      if (deserialisedData.data instanceof Array || !deserialisedData.data || deserialisedData.data.id) {
        pageState[k] = deserialisedData.data
        if (deserialisedData.meta) {
          pageState[`${k}_meta`] = deserialisedData.meta
        }
        delete pageState[stateErrorKey[index]]

        return
      }

      // FIXME: Need a best solution to handle non-serialized data
      if (deserialisedData.data.redirect_url) {
        pageState[k] = deserialisedData.data.redirect_url
        delete pageState[stateErrorKey[index]]
        return
      }

      if (errorMessage) message.error(errorMessage)
    })
    await Promise.all(promises)
    pageState[stateApiStatusKey] = "fulfilled"

    console.log("promise all", pageState)
    return
  }

  const deserialisedData = await deserialise(response.data)

  if (response.status === 403) {
    pageState[stateApiStatusKey] = "fulfilled"
  }

  if (deserialisedData.errors) {
    // TODO: Check if the following is required
    // if (method !== 'put') {
    //   delete pageState[stateDataKey]
    // }

    if (stateErrorKey instanceof Array) {
      pageState[stateErrorKey[0]] = deserialisedData.errors
      return
    }
    pageState[stateErrorKey] = deserialisedData.errors

    return
  }

  if (
    Object.keys(deserialisedData).length === 0 ||
    deserialisedData.data instanceof Array ||
    !deserialisedData.data ||
    deserialisedData.data.id
  ) {
    pageState[stateDataKey] = deserialisedData.data
    delete pageState[stateErrorKey]
    pageState[stateApiStatusKey] = "fulfilled"
    return
  }

  if (errorMessage) message.error(errorMessage)
}
