import React, { useState } from "react"
import { view } from "react-easy-state"
import { LoadingOutlined, CheckOutlined } from "@ant-design/icons"
import { Input, Button, Form } from "antd"

import LecturesService from "services/admin/lectures.service"
import CoursesService from "services/admin/courses.service"
import * as URLHelper from "helpers/url.helper"

const CurriculumLectureTextInlineEditForm = ({ pageState, text, lecture, disableEditMode }) => {
  const [loading, setLoading] = useState(false)

  const handleFinish = async (values) => {

    setLoading(true)

    await LecturesService.update({ lectureId: lecture.id, values, pageState })

    setLoading(false)

    if (pageState.API_LECTURES_UPDATE_STATUS === "fulfilled") {
      disableEditMode()
      const courseId = URLHelper.getAdminCourseId()
      // Here we are assuming that pageState is globalStore.ui.curriculum
      await CoursesService.curriculum({
        courseId,
        pageState,
        stateApiStatusKey: "API_CUSTOM_COURSES_CURRICULUM_STATUS",
      })
    }
  }

  return (
    <Form onFinish={handleFinish} initialValues={{ name: text }}>
      <div className="edit-mode-inside">
        <div>
          <Form.Item name="name" rules={[{ required: true, message: "Please enter lecture name!" }]}>
            <Input />
          </Form.Item>
        </div>
        <div>
          <Button type="primary" shape="circle" htmlType="submit">
            {loading ? <LoadingOutlined /> : <CheckOutlined />}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default view(CurriculumLectureTextInlineEditForm)
