import { store } from "react-easy-state"

const globalStore = store({
  currentUser: {},
  currentSchool: {},
  sider: {
    school: {},
    selectedItemChildren: [],
  },
  ui: {
    register: {},
    login: {},
    me: {},
    admin: {
      me: {},
      settings: {
        plans: {
          index: {},
        },
      },
      landing_pages: {
        index: {},
        show: {},
      },
    },
    user: {
      courses: { show: {} },
    },
    guest: {
      courses: {
        index: {},
      },
    },
    users: {
      profile: {},
    },
    student: {
      courses: {
        enrolled: {},
        index: {},
        show: {},
      },
      lectures: {
        show: {},
      },
      register: {},
    },
    courses: {
      index: {},
      new: {},
      information: {},
      curriculum: {},
    },
    lectures: {
      show: {},
    },
    school: {
      courses: {
        index: {},
        show: {},
        detail: {
          courseDetail: {},
        },
      },
    },
  },
})

// const globalStore = {
//   sider: store({}),
//   ui: {
//     register: store({}),
//     courses: {
//       index: store({}),
//       new: store({}),
//       information: store({}),
//       curriculum: store(),
//     }
//   }
// }

export default globalStore
