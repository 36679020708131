import { API, generic } from "api"

export default class LecturesService {
  static show = async ({ pageState, lectureId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_LECTURES_SHOW_STATUS",
      stateDataKey: ["lecture", "course", "lecture_comments"],
      stateErrorKey: ["lectureErrors", "courseErrors", "lectureCommentsErrors"],
      apiEndpoint: API.students.lectures.show,
      apiUrlReplacements: { lectureId },
      apiData: {},
      errorMessage: "Lecture could not be fetched",
    })
  }

  static complete = async ({ pageState, lectureId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_LECTURES_COMPLETE_STATUS",
      stateDataKey: ["next_lecture"],
      stateErrorKey: [`nextLectureErrors`],
      apiEndpoint: API.students.lectures.complete,
      apiUrlReplacements: { lectureId },
      apiData: {},
      errorMessage: "Lecture could not be updated",
    })
  }
}
