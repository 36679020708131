import React from 'react'
import { view } from 'react-easy-state'
import { Route, Redirect } from 'react-router-dom'
import * as AuthHelper from 'helpers/auth.helper'
import { message } from 'antd'

const PrivateRoute = ({ component: Component, loginRoute = '/login', ...rest }) => {
  
  
  const renderer = props => {
    if (!AuthHelper.isLoggedIn()) {
      message.info("You must be logged in to do that")
      return (
        <Redirect
          to={{
            pathname: loginRoute,
            state: { from: props.location }
          }}
        />
      )
    }
    return <Component {...props} />
  }

  return <Route {...rest} render={renderer} />
}

export default view(PrivateRoute)
