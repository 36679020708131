import React, { useEffect } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Col, Button } from "antd"

// import Footer from "components/common/layouts/Footer"

import globalStore from "store/index"
import SchoolPlans from "components/SchoolPlans/Index"
import ContentLoading from "components/ContentLoading/Index"
import ContentLoadingError from "components/ContentLoadingError/Index"
import StyledLink from "components/common/styled-components/StyledLink"
import StyledMainContent from "components/common/styled-components/StyledMainContent"

import PlansServices from "services/admin/plans.services"

const AdminSchoolPlanPage = ({history}) => {
  const pageState = globalStore.ui.admin.settings.plans.index

  useEffect(() => {
    const fetchPlans = async () => {
      await PlansServices.index({ pageState })
    }

    if (!pageState.plans) {
      fetchPlans()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderPageContent = () => {
    const { plans, current_plan, API_ADMIN_CM_PLANS_INDEX_STATUS } = pageState

    if (API_ADMIN_CM_PLANS_INDEX_STATUS === "pending") return <PageContent><ContentLoading /></PageContent>
    if (API_ADMIN_CM_PLANS_INDEX_STATUS === "rejected") return <PageContent><ContentLoadingError /></PageContent>

    return <PageContent><SchoolPlans plans={plans} currentPlan={current_plan} /></PageContent>
  }

  const PageContent = (props) => (
    <StyledMainContent containerBgColor="white">
      <Row justify="space-between">
        <Col>
          <StyledLink>
            <Button type="link" onClick={() => history.push(`/admin/courses`)}>
              <ArrowLeftOutlined /> Go to Curriculum
            </Button>
          </StyledLink>
        </Col>
        <Col>
        </Col>
      </Row>
      <div className="container">
        {props.children}
      </div>
    </StyledMainContent>
  )

  return (
    <React.Fragment>{renderPageContent()}</React.Fragment>
  )
}

export default withRouter(view(AdminSchoolPlanPage))
