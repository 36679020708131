import React from "react"
import { view } from "react-easy-state"
import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, Tooltip } from "antd";

import { renderAsHTML } from "utils/react"

const RichTextLectureContent = ({ content, deleting, onDelete }) => {

  return (
    <div className="form-card">
      <div className="asset-header">
        <h3>
          <FileTextOutlined /> Text
        </h3>
        <div>
          <div>
            <Tooltip title="Delete attachment">
              <Button type="danger" shape="circle" onClick={onDelete} loading={deleting}>
                {!deleting && <DeleteOutlined />}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div>{renderAsHTML(content.html)}</div>
    </div>
  )
}

export default view(RichTextLectureContent)
