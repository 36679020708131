const Color = {
  brandColor: "#9012fe",
  // brandColor: "#fa7268",
  headerBgColor: "#fff",
  pageColor: "#f3f3f3",
  right: "green",
  wrong: "red",
}

// Height in px
const Height = {
  header: 50,
  card: 400,
  schoolCard: 300,
  courseCard: 300,
}

const BoxShadow = {
  header: "0px 2px 5px #e4e4e4",
  card: {
    normal: "0px 17px 20px 0px #4a4a4a14",
    hover: "0px 0px 14px 0px #00000017",
  },
}

export { Color, Height, BoxShadow }
