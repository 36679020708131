import React, { useState } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import { Input, Button, message, Form } from "antd"

import globalStore from "store/index"
import CoursesService from "services/admin/courses.service"
import StyledCourseInformationCard from "components/common/styled-components/StyledCourseInformationCard"

const InformationDetails = () => {
  const pageState = globalStore.ui.courses.information
  const { course } = pageState
  const [submitting, setSubmitting] = useState(false)

  const handleFinish = async (values) => {
    setSubmitting(true)

    await CoursesService.update({ pageState, courseId: course.id, values })

    setSubmitting(false)

    if (["pending", "rejected"].includes(pageState.API_COURSES_UPDATE_STATUS)) {
      message.destroy()
      message.error("Failed to update course details")
      return
    }

    message.destroy()
    message.success("Course details updated successfully.")
  }

  return (
    <StyledCourseInformationCard>
      <div className="form-wrapper">
        <div className="form-info">
          <h3>Details</h3>
          <p>Change information about your course.</p>
        </div>
        <div className="form-card">
          <Form
            layout="vertical"
            onFinish={handleFinish}
            className="information-details-form"
            initialValues={{ name: course.name, subtitle: course.subtitle }}
          >
            <Form.Item
              label="Course Title"
              name="name"
              rules={[{ required: true, message: "Please enter course title!" }]}
            >
              <Input size="large" placeholder="e.g. 'Javascript for Beginners-Free'" />
            </Form.Item>
            <Form.Item
              label="Course Subtitle"
              name="subtitle"
              rules={[{ required: true, message: "Please enter subtitle!" }]}
            >
              <Input
                size="large"
                placeholder="e.g. 'The first step to learn JS curated list of projects that help you to understand basics of JavaScript'"
              />
            </Form.Item>
            <div className="align-left">
              <Button size="large" type="primary" htmlType="submit" loading={submitting}>
                {submitting ? "Saving..." : "Save"}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </StyledCourseInformationCard>
  )
}

export default withRouter(view(InformationDetails))
