import { API, generic } from "api"

export default class CoursesService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_COURSES_INDEX_STATUS",
      stateDataKey: "courses",
      stateErrorKey: "coursesErrors",
      apiEndpoint: API.admin.courses.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Courses could not be fetched",
    })
  }

  static create = async ({ pageState, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_COURSES_CREATE_STATUS",
      stateDataKey: "course",
      stateErrorKey: "createCourseErrors",
      apiEndpoint: API.admin.courses.create,
      apiUrlReplacements: {},
      apiData: { course: values },
      errorMessage: "Course could not be created",
    })
  }

  static update = async ({ pageState, courseId, values, formData }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_COURSES_UPDATE_STATUS",
      stateDataKey: "course",
      stateErrorKey: "courseErrors",
      apiEndpoint: API.admin.courses.update,
      apiUrlReplacements: { courseId },
      apiData: formData ? formData : { course: values },
      errorMessage: "Course could not be updated",
    })
  }

  static show = async ({ pageState, courseId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_COURSES_SHOW_STATUS",
      stateDataKey: "course",
      stateErrorKey: "courseErrors",
      apiEndpoint: API.admin.courses.show,
      apiUrlReplacements: { courseId },
      apiData: {},
      errorMessage: "Course could not be fetched",
    })
  }

  static information = async ({ courseId, pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_COURSES_INFORMATION_STATUS",
      stateDataKey: "course",
      stateErrorKey: "courseInformationErrors",
      apiEndpoint: API.admin.courses.information,
      apiUrlReplacements: { courseId },
      apiData: {},
      errorMessage: "Course information could not be fetched.",
    })
  }

  static publish = async ({ courseId, pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_COURSES_PUBLISH_STATUS",
      stateDataKey: "course",
      stateErrorKey: "coursePublishErrors",
      apiEndpoint: API.admin.courses.publish,
      apiUrlReplacements: { courseId },
      apiData: {},
      errorMessage: "Course could not be published.",
    })
  }

  static unpublish = async ({ courseId, pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_COURSES_UNPUBLISH_STATUS",
      stateDataKey: "course",
      stateErrorKey: "courseUnpublishErrors",
      apiEndpoint: API.admin.courses.unpublish,
      apiUrlReplacements: { courseId },
      apiData: {},
      errorMessage: "Course could not be unpublished.",
    })
  }

  static curriculum = async ({ courseId, pageState, stateApiStatusKey = "API_COURSES_CURRICULUM_STATUS" }) => {
    await generic({
      pageState,
      stateApiStatusKey,
      stateDataKey: "course",
      stateErrorKey: "courseCurriculumErrors",
      apiEndpoint: API.admin.courses.curriculum,
      apiUrlReplacements: { courseId },
      apiData: {},
      errorMessage: "Course curriculum could not be fetched.",
    })
  }


  static removeDisplayPicture = async ({ pageState, courseId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_COURSES_REMOVE_DISPLAY_PICTURE_STATUS",
      stateDataKey: "course",
      stateErrorKey: "courseErrors",
      apiEndpoint: API.admin.courses.remove_display_picture,
      apiUrlReplacements: { courseId },
      apiData: {},
      errorMessage: "Course's display picture could not be deleted",
    })
  }
}
