import React, { useEffect } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import Editor from "utils/grapesjs/Editor"
import { getAuthorization } from "api"

import globalStore from "store"
import LandingPagesService from "services/admin/landing-pages.service"
import ContentLoadingError from "components/ContentLoadingError/Index"
import ContentLoader from "components/common/ContentLoader"

const AdminLandingPagePage = ({
  match: {
    params: { landingPageId },
  },
}) => {
  // console.log("match", match)
  const pageState = globalStore.ui.admin.landing_pages.show

  useEffect(() => {
    const pageState = globalStore.ui.admin.landing_pages.show
    const fetchPageData = async () => {
      await LandingPagesService.show({ landingPageId, pageState })
    }
    fetchPageData()
  }, [landingPageId])

  const { landing_page, API_LANDING_PAGES_SHOW_STATUS } = pageState

  console.log("AdminLandingPagePage: API_LANDING_PAGES_SHOW_STATUS:", API_LANDING_PAGES_SHOW_STATUS)
  if (!API_LANDING_PAGES_SHOW_STATUS) return <ContentLoader />
  if (API_LANDING_PAGES_SHOW_STATUS === "pending") return <ContentLoader />
  if (API_LANDING_PAGES_SHOW_STATUS === "rejected") return <ContentLoadingError />

  // const components = landing_page.components ? JSON.parse(landing_page.components) : landing_page.html
  // const style = landing_page.style ? JSON.parse(landing_page.style) : landing_page.css

  const components = landing_page.components || landing_page.html
  const style = landing_page.style || landing_page.css

  // console.log("components:", components)
  // console.log("style:", style)

  const onInit = (editor) => {
    editor.setComponents(components)
    editor.setStyle(style)
  }

  return (
    <div style={{ width: "100%" }}>
      <Editor
        presetType="webpage"
        plugins={[]}
        onInit={onInit}
        storageManager={{
          type: "remote",
          stepsBeforeSave: 3,
          urlLoad: `${process.env.REACT_APP_API_BASE_URL}/api/school-admin/landing-pages/${landingPageId}/load`,
          urlStore: `${process.env.REACT_APP_API_BASE_URL}/api/school-admin/landing-pages/${landingPageId}/store`,
          headers: { Authorization: getAuthorization() },
          credentials: "omit",
          autoload: false,
        }}
        components={JSON.parse(JSON.stringify(components))}
        style={JSON.parse(JSON.stringify(style))}
      />
    </div>
  )
}

export default withRouter(view(AdminLandingPagePage))
