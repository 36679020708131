import { API, generic } from "api"

export default class LecturesService {
  static show = async ({ pageState, lectureId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_LECTURES_SHOW_STATUS",
      stateDataKey: ["lecture"],
      stateErrorKey: ["lectureErrors"],
      apiEndpoint: API.admin.lectures.show,
      apiUrlReplacements: { lectureId },
      apiData: {},
      errorMessage: "Lecture could not be fetched",
    })
  }

  static create = async ({ pageState, sectionId }) => {
    if (!sectionId) return
    await generic({
      pageState,
      stateApiStatusKey: "API_LECTURES_CREATE_STATUS",
      // We don't use 'course' here so that we update only the lecture in the pageState of curriculum
      // This is because we need to redirect to the lecture and we need the lectureId
      // Right now I do not know the correct way to pass data of multiple types via fast_jsonapi
      stateDataKey: ["lecture"],
      stateErrorKey: ["createLectureErrors"],
      apiEndpoint: API.admin.lectures.create,
      apiUrlReplacements: { sectionId },
      apiData: { lecture: { name: "New Lecture" } },
      errorMessage: "Lecture could not be created",
    })
  }

  static update = async ({ pageState, lectureId, values }) => {
    if (!lectureId) return
    await generic({
      pageState,
      stateApiStatusKey: "API_LECTURES_UPDATE_STATUS",
      // We use 'course' here so that we can update the course in the pageState of curriculum
      stateDataKey: ["lecture"],
      stateErrorKey: [`updateLectureErrors-${lectureId}`],
      apiEndpoint: API.admin.lectures.update,
      apiUrlReplacements: { lectureId },
      apiData: { lecture: values },
      errorMessage: "Lecture could not be updated",
    })
  }

  static reorder = async ({ lectureId, pageState, stateApiStatusKey = "API_LECTURES_REORDER_STATUS", values }) => {
    await generic({
      pageState,
      stateApiStatusKey,
      stateDataKey: "course",
      stateErrorKey: "courseErrors",
      apiEndpoint: API.admin.lectures.reorder,
      apiUrlReplacements: { lectureId },
      apiData: { ...values },
      errorMessage: "Course could not be fetched.",
    })
  }

  static publish = async ({ pageState, lectureId }) => {
    if (!lectureId) return
    await generic({
      pageState,
      stateApiStatusKey: "API_LECTURES_PUBLISH_STATUS",
      stateDataKey: ["lecture"],
      stateErrorKey: [`lectureErrors`],
      apiEndpoint: API.admin.lectures.publish,
      apiUrlReplacements: { lectureId },
      apiData: {},
      errorMessage: "Lecture could not be published",
    })
  }

  static unpublish = async ({ pageState, lectureId }) => {
    if (!lectureId) return
    await generic({
      pageState,
      stateApiStatusKey: "API_LECTURES_UNPUBLISH_STATUS",
      stateDataKey: ["lecture"],
      stateErrorKey: [`lectureErrors`],
      apiEndpoint: API.admin.lectures.unpublish,
      apiUrlReplacements: { lectureId },
      apiData: {},
      errorMessage: "Lecture could not be unpublished",
    })
  }
}
