import React from "react"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { Row, Col, Table, Button, message } from "antd"

import Header from "components/common/layouts/Header"
import Footer from "components/common/layouts/Footer"
import PricingForm from "components/pages/courses/PricingForm"

const planColSpans = {
  xs: {
    span: 20,
    offset: 2,
  },
  sm: {
    span: 12,
    offset: 0,
  },
  md: {
    span: 12,
    offset: 0,
  },
}

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "PLAN TYPE",
    dataIndex: "planType",
    key: "planType",
  },
  {
    title: "PLAN NAME",
    dataIndex: "planName",
    key: "planName",
  },
  {
    title: "PRICE",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "RECURRING",
    dataIndex: "recurring",
    key: "recurring",
  },
  {
    title: "PURCHASE URL",
    dataIndex: "purchaseURL",
    key: "purchaseURL",
    render: (url, record) => <Button type="link">Copy</Button>,
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    render: () => {
      return (
        <React.Fragment>
          <Button type="primary" shape="circle" style={{ marginRight: 15 }}>
            <EditOutlined />
          </Button>
          <Button shape="circle">
            <DeleteOutlined />
          </Button>
        </React.Fragment>
      )
    },
  },
]

class PricingPage extends React.Component {
  state = {
    pricingForm: null,
    activePlans: [
      {
        key: "1",
        id: "#123",
        planType: "Free",
        planName: "Free Course",
        price: "Free",
        recurring: "No",
        purchaseURL: "#",
      },
    ],
  }

  showPricingForm = (formType) => {
    this.setState({ pricingForm: formType })
  }

  handlePricingForm = (planType, formData) => {
    const id = Date.now().toString().substring(1, 5)

    let newplanType = "Free"
    let price = "Free"

    if (planType === "one-time") {
      newplanType = "One-Time Purchase"
      price = `${formData.price} ${formData.currency}`
    }

    const newPlan = {
      id: `#${id}`,
      key: id,
      planType: newplanType,
      planName: formData.name,
      price,
      recurring: "No",
      purchaseURL: "#",
    }

    message.success("New pricing plan is added successfully.")
    this.setState({ activePlans: [...this.state.activePlans, newPlan], pricingForm: null })
  }

  hidePricingForm = () => {
    this.setState({ pricingForm: null })
  }

  render() {
    const { pricingForm, activePlans } = this.state

    return (
      <div className="page course-page course-pricing-page">
        <Header content={<h3>Pricing</h3>} />
        <div className="new-pricing__wrapper">
          <div className="new-pricing__title">New Pricing</div>
          {pricingForm ? (
            <PricingForm
              type={pricingForm}
              hidePricingForm={this.hidePricingForm}
              onSubmitPricingForm={this.handlePricingForm}
            />
          ) : (
            <Row>
              <Col {...planColSpans}>
                <div className="plan-item" onClick={() => this.showPricingForm("free")}>
                  <img src="https://api.adorable.io/avatars/pricing-free.svg" alt="free-plan" />
                  <h4>Free</h4>
                  <p>No Payment</p>
                </div>
              </Col>
              <Col {...planColSpans}>
                <div className="plan-item" onClick={() => this.showPricingForm("one-time")}>
                  <img src="https://api.adorable.io/avatars/pricing-purchase.svg" alt="one-time payment" />
                  <h4>One-Time Payment</h4>
                  <p>A Single Payment</p>
                </div>
              </Col>
            </Row>
          )}
        </div>
        <div className="active-plan__wrapper">
          <Table dataSource={activePlans} columns={columns} pagination={false} />
        </div>
        <Footer />
      </div>
    )
  }
}

export default PricingPage
