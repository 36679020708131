import React from "react"
import Icon from "@ant-design/icons"

const DragSvg = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 640" version="1.1" x="0px" y="0px" id="svg4206">
      <g
        id="g4212"
        transform="translate(0,68)"
        style={{ fill: "none", fillRule: "evenodd", stroke: "none", strokeWidth: 1 }}
      >
        <g transform="translate(96,28)" id="g4214" style={{ fill: "currentColor" }}>
          <circle cx="45" cy="45" r="45" id="circle4216" />
          <circle cx="274" cy="45" r="45" id="circle4218" />
          <circle cx="45" cy="228" r="45" id="circle4220" />
          <circle cx="274" cy="228" r="45" id="circle4222" />
          <circle cx="45" cy="411" r="45" id="circle4224" />
          <circle cx="274" cy="411" r="45" id="circle4226" />
        </g>
      </g>
    </svg>
  )
}

const DragIcon = (props) => <Icon component={DragSvg} {...props} />
export default DragIcon
