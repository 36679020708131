import React from "react"
import { BulbOutlined, MessageOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Alert } from "antd";
import Header from "components/common/layouts/Header"
import Footer from "components/common/layouts/Footer"

const CourseCommentsPage = () => {
  return (
    <div className="page courses-page course-comments-page">
      <Header content={<h3>Comments</h3>} />
      <div className="comments-wrapper">
        <Alert
          message="Tip"
          description="To toggle comments moderation and multi-threaded responses,go to General Settings."
          type="info"
          icon={<BulbOutlined />}
          showIcon
        />
        <div className="form-card comment-box">
          <MessageOutlined />
          <h2>No Comments Have Been Made On Your Lectures</h2>
          <p>
            Comments allow students and instructors to interact with one another. You can enable comments for a
            lecture inside the curriculum editor.
          </p>
        </div>
        <Alert
          message="Learn more about adding comments to lectures in the CourseMango Knowledge Base"
          type="error"
          showIcon
          icon={<QuestionCircleOutlined />}
          style={{ maxWidth: "65%", margin: "20px auto" }}
        />
      </div>
      <Footer />
    </div>
  )
}

export default CourseCommentsPage
