import React from "react"
import { view } from "react-easy-state"
import AceEditor from "react-ace"
import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons"
import { Button, Tooltip } from "antd"

// allowed languages
import "brace/mode/html"
import "brace/mode/css"
import "brace/mode/javascript"
import "brace/mode/ruby"
import "brace/mode/php"
import "brace/mode/json"

// allowed theme
import "brace/theme/monokai"

const CodeLectureContent = ({ content, onDelete, deleting }) => {
  return (
    <div className="form-card">
      <div className="asset-header">
        <h3>
          <FileTextOutlined /> Code
        </h3>
        <div>
          <div>
            <Tooltip title="Delete attachment">
              <Button type="danger" shape="circle" onClick={onDelete} loading={deleting}>
                {!deleting && <DeleteOutlined />}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div>
        <AceEditor mode={content.language} readOnly={true} theme="monokai" name="code-editor" value={content.code} />
      </div>
    </div>
  )
}

export default view(CodeLectureContent)
