import React from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import { Button } from "antd"

const ButtonNewSection = ({ text = "New Section", history, pageState = {} }) => {
  const handleClick = () => {
    const { course } = pageState
    if (!course && !course.id) return
    history.push(`/admin/courses/${course.id}/curriculum/section/new`)
  }

  return (
    <Button type="primary" onClick={handleClick}>
      {text}
    </Button>
  )
}
export default withRouter(view(ButtonNewSection))
