import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import { CheckCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Collapse } from "antd";

const StyledCourseContentSider = styled.div`
  width: 100%;
  height: auto;
  & > .header {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0px 0px 20px;
    font-size: 1.2em;
    border-bottom: 1px solid #8c16fd1f;
  }
  & > .ant-collapse {
    border: 0px;
    border-radius: 0px;
    background: transparent;
    & > .ant-collapse-item {
      border: 0px;
      & > .ant-collapse-content {
        border: 0px;
        & > .ant-collapse-content-box {
          padding: 0px;
        }
      }
    }
  }
`

const StyledLectureItem = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  transition: 0.5s;
  cursor: pointer;
  background-color: ${(props) => (props.active ? "#f1f1f1" : "white")};
  &:hover {
    background-color: #8c16fd1f;
  }
  & > div:nth-of-type(1) {
    & > i {
      font-size: 1.2em;
    }
    & > span {
      margin-left: 20px;
    }
  }
  & > div:nth-of-type(2) {
    & > span {
      color: #adaaaa;
    }
  }
`

const StyledSectionName = styled.div`
  font-weight: 500;
  font-size: 1.1em;
`

const StyledCompletedIcon = styled.span`
  color: #08af08;
`

const CourseContentSider = ({ sections = [], history, match }) => {
  const { courseId, lectureId } = match.params
  const initialActiveKeys = sections.map((s) => s.id)

  const [activeKeys, setActiveKeys] = useState(initialActiveKeys)

  // FIXME: Why is this being used? Please explain.
  // This goes in infinite loop. Please discuss.
  useEffect(() => {
    if (activeKeys.length !== 0 || initialActiveKeys.length === activeKeys.length) return

    setActiveKeys(initialActiveKeys)
  }, [activeKeys, initialActiveKeys])

  return (
    <StyledCourseContentSider>
      <div className="header">Curriculum</div>
      <Collapse
        activeKey={activeKeys}
        onChange={(keys) => {
          setActiveKeys(keys)
        }}
      >
        {sections.map((section) => {
          const { lectures } = section

          if (lectures.length === 0) return null

          return (
            <Collapse.Panel header={<StyledSectionName>{section.name}</StyledSectionName>} key={section.id}>
              {lectures.map((lecture) => {
                return (
                  <StyledLectureItem
                    key={lecture.id}
                    onClick={() => history.push(`/courses/${courseId}/lectures/${lecture.id}`)}
                    completed={lecture.completed}
                    active={lectureId === lecture.id}
                  >
                    <div>
                      <PlayCircleOutlined />
                      <span>{lecture.name}</span>
                    </div>
                    <div>
                      <span>
                        {lecture.completed && (
                          <StyledCompletedIcon>
                            <CheckCircleOutlined />
                          </StyledCompletedIcon>
                        )}
                      </span>
                    </div>
                  </StyledLectureItem>
                );
              })}
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </StyledCourseContentSider>
  );
}

export default withRouter(CourseContentSider)
