import React from "react"
// import { Button, Icon } from "antd"

// const InformationDelete = (props) => (
//   <div className="form-wrapper">
//     <div className="form-info">
//       <h3>TODO Delete</h3>
//       <p>Permanently delete all course content, including student enrollment records.</p>
//     </div>
//     <div className="form-card">
//       <Button type="danger" size="large">
//         <Icon type="delete" /> Delete Course Permanently
//       </Button>
//     </div>
//   </div>
// )

const InformationDeleteEmpty = () => <React.Fragment></React.Fragment>

export default InformationDeleteEmpty
