import { API, generic } from "api"

export default class CoursesService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_COURSES_INDEX_STATUS",
      stateDataKey: ["courses"],
      stateErrorKey: ["coursesErrors"],
      apiEndpoint: API.guests.courses.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Courses could not be fetched",
    })
  }
}
