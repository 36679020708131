import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { InboxOutlined } from '@ant-design/icons';
import { Upload, message, Button } from "antd";

import globalStore from "store/index"
import CoursesService from "services/admin/courses.service"
import StyledCourseInformationCard from "components/common/styled-components/StyledCourseInformationCard"

const StyledImage = styled.div`
  & > img {
    width: 100%;
  }
`

const StyledActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
`

const InformationBranding = ({ match }) => {
  const pageState = globalStore.ui.courses.information
  const { course } = pageState
  const { courseId } = match.params
  
  const [uploading, setUploading] = useState(false)
  const [fileList, setFileList] = useState([])
  const [removeButtonLoading, setRemoveButtonLoading] = useState(false)
  const [displayPictureUrl, setDisplayPictureUrl] = useState(false)

  // FIXME: ESLint issue: React Hook useEffect has a missing dependency: 'displayPictureUrl'. Either include it or remove the dependency array
  useEffect(() => {
    const { course } = pageState

    if (course.display_picture_url === displayPictureUrl) return

    setDisplayPictureUrl(course.display_picture_url)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course.display_picture_url])

  const upload = async () => {
    if (fileList.length === 0) {
      message.destroy()
      message.error("Please select image to upload")
      return
    }

    const formData = new FormData()

    formData.append("course[file]", fileList[0])

    setUploading(true)

    await CoursesService.update({ pageState, courseId, formData })

    setUploading(false)

    const { courseErrors } = pageState

    if (courseErrors) {
      message.error("Failed to update course branding picture")
      return
    }

    message.success("Course branding picture updated successfully.")
    setFileList([])
  }

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)

      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
    fileList,
  }

  const removeDisplayPicture = async () => {

    setRemoveButtonLoading(true)

    await CoursesService.removeDisplayPicture({ pageState, courseId })

    setRemoveButtonLoading(false)

    const { course } = pageState

    if(!course.display_picture_url) {
      message.success("Display picture is removed successfully.")
      return
    }

    message.error("Failed to remove display picture.")
  }

  const renderUploader = () => {
    return (
      <Upload.Dragger {...draggerProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
        </p>
      </Upload.Dragger>
    );
  }

  const renderImage = () => {
    return (
      <StyledImage>
        <img src={displayPictureUrl} alt="Course's display" />
      </StyledImage>
    )
  }

  const renderMainContent = () => {
    if (displayPictureUrl) return renderImage()
    return renderUploader()
  }

  const renderActionButton = () => {
    if (displayPictureUrl) {
      return (
        <StyledActionButtons>
          <Button type="primary" size="large" disabled={removeButtonLoading} onClick={() => setDisplayPictureUrl(null)}>
            Change Image
          </Button>
          <Button danger size="large" loading={removeButtonLoading}  onClick={removeDisplayPicture}>
            Remove Image
          </Button>
        </StyledActionButtons>
      )
    }
    return (
      <div>
        <Button type="primary" size="large" loading={uploading} onClick={upload}>
          {uploading ? "Uploading" : "Upload"} Image
        </Button>
        {course.display_picture_url && (
          <Button
            size="large"
            style={{ marginLeft: 20 }}
            onClick={() => setDisplayPictureUrl(course.display_picture_url)}
          >
            Discard
          </Button>
        )}
      </div>
    )
  }

  return (
    <StyledCourseInformationCard>
      <section className="form-wrapper" id="branding">
        <div className="form-info">
          <h3>Branding</h3>
          <p>Brand your course by setting a custom thumbnail logo, featured background.</p>
        </div>
        <div className="form-card">
          <div className="branding-thumbnail__wrapper">
            <h3>Thumbnail</h3>
            {renderMainContent()}
          </div>
          <div>{renderActionButton()}</div>
        </div>
      </section>
    </StyledCourseInformationCard>
  )
}

export default withRouter(InformationBranding)
