import { deserialise } from "kitsu-core"
import { message } from "antd"

import { API, apiClient, call, generic } from "api"

export default class SchoolsService {
  static create = async ({ values, pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOLS_CREATE_STATUS",
      stateDataKey: "school",
      stateErrorKey: "createSchoolErrors",
      apiEndpoint: API.admin.schools.create,
      apiUrlReplacements: {},
      apiData: { school: values },
      errorMessage: "School could not be created.",
    })

    if (pageState.createSchoolErrors) {
      pageState.stepStatus = "process"
      return
    }

    if (pageState.school) {
      pageState.currentStep = "done"
      pageState.stepStatus = "process"
    }

    // let response
    // try {
    //   pageState.API_SCHOOLS_CREATE_STATUS = 'pending'
    //   const { method, url } = API.admin.schools.create
    //   response = await call(apiClient()[method], url, { school: values })
    // } catch (e) {
    //   pageState.API_SCHOOLS_CREATE_STATUS = 'rejected'
    //   if (e.name === 'ServerNotReachableError') {
    //     message.error('Unable to reach server, please try after some time')
    //     pageState.serverNotReachable = true
    //     return
    //   }
    //   message.error('An error occurred. Please try after some time')
    //   throw (e)
    // }

    // // console.log('response', response)
    // const deserialisedData = await deserialise(response.data)
    // // console.log('deserialisedData', deserialisedData)

    // if (deserialisedData.errors) {
    //   pageState.createSchoolErrors = deserialisedData.errors
    //   pageState.stepStatus = 'process'
    //   return
    // }

    // if (!!deserialisedData.data.id) {
    //   console.log('deserialisedData.data', deserialisedData.data)
    //   pageState.school = deserialisedData.data
    //   pageState.currentStep = 'done'
    //   pageState.stepStatus = 'process'
    //   pageState.API_SCHOOLS_CREATE_STATUS = 'fulfilled'
    //   return
    // }

    // message.error('School could not be created.')
  }

  static uploadDisplayPicture = async ({ pageState, schoolId, formData }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOLS_UPLOAD_DISPLAY_PICTURE_STATUS",
      stateDataKey: ["school"],
      stateErrorKey: ["schoolErrors"],
      apiEndpoint: API.admin.schools.upload_display_picture,
      apiUrlReplacements: { schoolId },
      apiData: formData,
      errorMessage: "School's display picture could not be updated.",
    })
  }

  static removeDisplayPicture = async ({ pageState, schoolId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOLS_REMOVE_DISPLAY_PICTURE_STATUS",
      stateDataKey: ["school"],
      stateErrorKey: ["schoolErrors"],
      apiEndpoint: API.admin.schools.remove_display_picture,
      apiUrlReplacements: { schoolId },
      apiData: {},
      errorMessage: "School's display picture could not be deleted.",
    })
  }

  static me = async ({ pageState }) => {
    let response
    try {
      pageState.API_SCHOOLS_ME_STATUS = "pending"
      const { method, url } = API.admin.schools.me
      response = await call(apiClient()[method], url, {})
    } catch (e) {
      pageState.API_SCHOOLS_ME_STATUS = "rejected"
      if (e.name === "ServerNotReachableError") {
        message.error("Unable to reach server, please try after some time")
        pageState.serverNotReachable = true
        return
      }
      message.error("An error occured, please try after some time")
      throw e
    }

    console.log("response", response)
    const deserialisedData = await deserialise(response.data)
    console.log("deserialisedData", deserialisedData)

    if (deserialisedData.errors) {
      delete pageState.school
      pageState.schoolErrors = deserialisedData.errors
      return
    }

    pageState.school = deserialisedData.data
    delete pageState.schoolErrors
    pageState.API_SCHOOLS_ME_STATUS = "fulfilled"
  }
}
