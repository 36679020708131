import React, { useEffect } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Col, Button } from "antd"

// import Header from "components/common/layouts/Header"
// import Footer from "components/common/layouts/Footer"

import InformationPricing from "components/pages/courses/InformationPricing"
import InformationPublish from "components/pages/courses/InformationPublish"
import InformationPreview from "components/pages/courses/InformationPreview"
import InformationDetails from "components/pages/courses/InformationDetails"
import InformationBranding from "components/pages/courses/InformationBranding"
import InformationDelete from "components/pages/courses/InformationDelete"
import InformationDuplicate from "components/pages/courses/InformationDuplicate"
import CoursesService from "services/admin/courses.service"
import globalStore from "store/index"
import ContentLoader from "components/common/ContentLoader"
import ContentLoadingError from "components/ContentLoadingError/Index"
import StyledMainContent from "components/common/styled-components/StyledMainContent"
import StyledLink from "components/common/styled-components/StyledLink"

import * as URLHelper from "helpers/url.helper"

const CourseInformationPage = ({ history }) => {
  const courseId = URLHelper.getAdminCourseId()
  useEffect(() => {
    if (!courseId) return
    const fetchPageData = async () => {
      const pageState = globalStore.ui.courses.information

      await CoursesService.information({ courseId, pageState })
    }
    fetchPageData()
  }, [courseId])

  const renderPageContent = () => {
    const pageState = globalStore.ui.courses.information
    const { course, API_COURSES_INFORMATION_STATUS } = pageState

    if (API_COURSES_INFORMATION_STATUS === "rejected") return <ContentLoadingError />
    if (!course) return <ContentLoader />
    const courseId = URLHelper.getAdminCourseId()
    if (course.id !== courseId) return <ContentLoader />

    return (
      <React.Fragment>
        <InformationPublish topCard={true}/>
        <InformationPricing />
        <InformationPreview />
        <InformationDetails />
        <InformationBranding />
        <InformationDelete />
        <InformationDuplicate />
      </React.Fragment>
    )
  }

  return (
    <StyledMainContent>
      <Row justify="space-between">
        <Col>
          <StyledLink>
            <Button type="link" onClick={() => history.push("/admin/courses")}>
              <ArrowLeftOutlined /> Go to Courses
            </Button>
          </StyledLink>
        </Col>
        <Col>
        </Col>
      </Row>
      <div className="container">{renderPageContent()}</div>
    </StyledMainContent>
  )
}

export default withRouter(view(CourseInformationPage))
