import React from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import { Button } from "antd"
import { PlusSquareFilled } from "@ant-design/icons"

const NewCourseButton = ({ text = "New Course", history }) => {
  return (
    <Button type="primary" onClick={() => history.push("/admin/courses/new")} icon={<PlusSquareFilled />}>
      {text}
    </Button>
  )
}
export default withRouter(view(NewCourseButton))
