import { API, generic } from "api"

export default class LandingPagesService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_LANDING_PAGES_INDEX_STATUS",
      stateDataKey: "landing_pages",
      stateErrorKey: "landing_pagesErrors",
      apiEndpoint: API.admin.landing_pages.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Landing Pages could not be fetched",
    })
  }

  static show = async ({ pageState, landingPageId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_LANDING_PAGES_SHOW_STATUS",
      stateDataKey: "landing_page",
      stateErrorKey: "landing_pageErrors",
      apiEndpoint: API.admin.landing_pages.show,
      apiUrlReplacements: { landingPageId },
      apiData: {},
      errorMessage: "Landing Page could not be fetched",
    })
  }
}
