import React, { useState } from "react"
import styled from "styled-components"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import { YoutubeOutlined } from "@ant-design/icons"
import { message, Input, Button, Form } from "antd"

import ContentsService from "services/admin/contents.service"

import globalStore from "store/index"

const StyledWrapper = styled.div`
  padding: 20px 0px;
  & > button {
    margin-top: 20px;
  }
`

const NewLectureAddYoutubeVideo = ({ match }) => {
  const pageState = globalStore.ui.lectures.show
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = useState(false)

  const handleFinish = async (values) => {
    const { lectureId } = match.params
    const postData = {
      content: {
        details: {
          youtube_url: values["youtube_url"],
        },
        content_type: "video",
      },
    }

    setSubmitting(true)
    await ContentsService.create({ pageState, lectureId, values: postData })
    setSubmitting(false)

    if (pageState.lectureErrors) {
      message.error("Failed to save video url, please try again")
      return
    }
    form.resetFields()
    message.success("Video url added successfully.")
  }

  return (
    <StyledWrapper>
      <Form form={form} onFinish={handleFinish}>
        <Form.Item name="youtube_url" rules={[{ required: true, message: "Please enter youtube video url" }]}>
          <Input
            size="large"
            prefix={<YoutubeOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Youtube Video URL"
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" size="large" loading={submitting} block>
          Save
        </Button>
      </Form>
    </StyledWrapper>
  )
}

export default withRouter(view(NewLectureAddYoutubeVideo))
