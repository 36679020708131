import React, { useEffect } from "react"
import { view } from "react-easy-state"
import styled from "styled-components"
import { Input } from "antd"

import { Color } from "constant/theme"
import Header from "components/Header/Index"
import CourseCardList from "components/CourseCardList/Index"
import ContentLoading from "components/ContentLoading/Index"
import ContentLoadingError from "components/ContentLoadingError/Index"
import globalStore from "store/index"
import * as AuthHelper from "helpers/auth.helper"

import GuestCoursesService from "services/guest/courses.service"
import StudentCoursesService from "services/student/courses.service"

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverImage = styled.div`
  background-image: url(https://images.unsplash.com/photo-1554415707-6e8cfc93fe23?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);
  height: 400px;
  clip-path: ellipse(85% 100% at 50% 0%);
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    height: inherit;
    padding-left: 50px;
    background: linear-gradient(45deg, ${Color.brandColor}, transparent);
    & > div {
      max-width: 400px;
      & > h1,
      & > p {
        color: white;
      }
    }
  }
`

const CoursesIndexPage = () => {
  const storeKey = AuthHelper.isLoggedIn() ? "student" : "guest"
  const pageState = globalStore.ui[storeKey].courses.index

  useEffect(() => {
    // This is to ensure that pageState is not needed to be passed to useEffect
    const pageState = globalStore.ui[storeKey].courses.index
    const fetchPageData = async () => {
      if (storeKey === "student") {
        await StudentCoursesService.index({ pageState })
        return
      }

      await GuestCoursesService.index({ pageState })
    }
    fetchPageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeKey])

  const renderPageContent = () => {
    const { courses, API_COURSES_INDEX_STATUS } = pageState

    if (!API_COURSES_INDEX_STATUS) return <ContentLoading />
    if (API_COURSES_INDEX_STATUS === "pending") return <ContentLoading />
    if (API_COURSES_INDEX_STATUS === "rejected") return <ContentLoadingError />

    return <CourseCardList courses={courses} title="All Courses" />
  }

  console.log("before return")

  return (
    <StyledPage>
      <Header />
      <StyledPageContent>
        <StyledPageContentCoverImage>
          <div>
            <div>
              <h1>Learn on your schedule</h1>
              <p>Study any topic, anytime. Explore thousands of courses starting at ₹385 each.</p>
              <Input.Search
                className="filter-input"
                style={{ width: 300 }}
                size="large"
                placeholder="Find a course"
                enterButton
              />
            </div>
          </div>
        </StyledPageContentCoverImage>
        {renderPageContent()}
      </StyledPageContent>
      {/* <Footer /> */}
    </StyledPage>
  )
}

export default view(CoursesIndexPage)
