import React from "react"
import { withRouter } from "react-router-dom"
import { view } from "react-easy-state"
import styled from "styled-components"
import { EditOutlined, ReadOutlined } from "@ant-design/icons"
import { Row, Col, Tooltip, Empty, Button } from "antd"

import { Height, BoxShadow } from "constant/theme"

import NewCourseButton from "components/common/NewCourseButton"
import StyledCourseCardBackground from "components/common/styled-components/StyledCourseCardBackground"

const StyledCard = styled.div`
  width: 100%;
  height: ${Height.courseCard}px;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: ${BoxShadow.card.hover};
  }
  & > div.course-details {
    position: absolute;
    width: 100%;
    top: 0px;
    height: inherit;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(0deg, #00000094, transparent);
  }
`
const StyledCourseName = styled.div`
  margin-bottom: 10px;
  font-size: 1rem;
  color: white;
  background: #00000066;
  padding: 5px;
  border-radius: 4px;
  & > h3 {
    color: white;
  }
  & > p {
    margin: 0px;
  }
`

const StyledActionButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5em;
  & > div {
    & > i {
      color: white;
    }
  }
`

const StyledEditButton = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  background: #34343c;
  color: white;
`

const CourseList = ({ history, courses }) => {
  const courseColSpans = {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 8 },
  }

  const handleCourseCardClick = (courseId) => {
    history.push(`/admin/courses/${courseId}/curriculum`)
  }

  if (!courses || courses.length === 0) {
    const emptyContent = <strong>It seems you do not have any course created</strong>
    return (
      <Empty description={emptyContent} style={{ marginTop: 100 }}>
        <NewCourseButton text="Create Your First Course" />
      </Empty>
    )
  }

  return (
    <div className="course-list">
      <Row>
        {courses.map((course) => {
          return (
            <Col key={course.id} {...courseColSpans}>
              <StyledCard>
                <StyledCourseCardBackground url={course.display_picture_url}>
                  {!course.display_picture_url && <ReadOutlined />}
                </StyledCourseCardBackground>
                <div className="course-details">
                  <StyledActionButton>
                    <div>{/* <HeartOutlined /> */}</div>
                    <div>
                      <StyledEditButton onClick={() => history.push(`/admin/courses/${course.id}/information`)}>
                        <EditOutlined />
                      </StyledEditButton>
                    </div>
                  </StyledActionButton>
                  <div>
                    <Tooltip placement="top" title={course.name} arrowPointAtCenter>
                      <StyledCourseName>
                        <h3>{course.name}</h3>
                        <p>{course.subtitle}</p>
                      </StyledCourseName>
                    </Tooltip>
                    <Button type="primary" onClick={() => handleCourseCardClick(course.id)} block>
                      View Course
                    </Button>
                  </div>
                </div>
              </StyledCard>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default withRouter(view(CourseList))
