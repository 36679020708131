import { API, generic } from "api"

export default class SchoolPlansService {
  static create = async ({ pageState, courseId, values }) => {
    if (!courseId) return
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOL_PLANS_CREATE_STATUS",
      stateDataKey: ["course"],
      stateErrorKey: ["courseErrors"],
      apiEndpoint: API.admin.school_plans.create,
      apiUrlReplacements: { courseId },
      apiData: { school_plan: values },
      errorMessage: "Plan could not be created",
    })
  }

  static update = async ({ pageState, planId, values }) => {
    if (!planId) return
    await generic({
      pageState,
      stateApiStatusKey: "API_SCHOOL_PLANS_UPDATE_STATUS",
      stateDataKey: ["course"],
      stateErrorKey: ["courseErrors"],
      apiEndpoint: API.admin.school_plans.update,
      apiUrlReplacements: { planId },
      apiData: { school_plan: values },
      errorMessage: "Plan could not be updated",
    })
  }
}
