import React from "react"
// import { Button } from "antd"

// const InformationDuplicate = (props) => (
//   <div className="form-wrapper">
//     <div className="form-info">
//       <h3>TODO Duplicate</h3>
//       <p>
//         All course content, except for student enrollment records, will be duplicated in a separate course. It will not
//         affect this course.
//       </p>
//     </div>
//     <div className="form-card">
//       <Button type="primary" size="large">
//         Duplicate Course
//       </Button>
//     </div>
//   </div>
// )

const InformationDuplicateEmpty = () => <React.Fragment></React.Fragment>

export default InformationDuplicateEmpty
