import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { view } from "react-easy-state"
import { InboxOutlined } from "@ant-design/icons"
import { Row, Col, Modal, Upload } from "antd"

import { Color } from "constant/theme"

import SchoolCard from "components/SchoolCard/Index"

const StyledCardList = styled.div`
  margin: 0px 0px 50px 0px;
  & > h1 {
    text-transform: uppercase;
    margin: 0px;
    color: white;
    font-size: 1.5em;
    margin-bottom: 30px;
  }
  &:nth-of-type(2) > h1 {
    color: ${Color.brandColor};
  }
`

const CardList = ({ schools = [], title, itemsPerRow = 3, owned = false, onUpload, uploading, uploadSuccess }) => {
  const validItemsPerRow = [2, 3, 4, 6]
  const [uploadModalVisible, setUploadModalVisible] = useState(false)
  const [fileList, setFileList] = useState([])
  const [activeSchoolId, setSchoolId] = useState(null)

  const span = validItemsPerRow.includes(itemsPerRow) ? 24 / itemsPerRow : 8

  const handleOpenUploadModal = ({ schoolId }) => {
    setSchoolId(schoolId)

    setUploadModalVisible(true)
  }

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)

      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])

      return false
    },
    fileList,
  }

  useEffect(() => {
    if (uploadSuccess) {
      setSchoolId(null)
      setFileList([])
      setUploadModalVisible(false)
    }
  }, [uploadSuccess])

  const handleUpload = () => {
    onUpload({ fileList, schoolId: activeSchoolId })
  }

  return (
    <StyledCardList>
      {title && <h1>{title}</h1>}
      <Row type="flex" justify="start" align="top" gutter={20}>
        {schools.map((s, i) => (
          <Col span={span} key={i}>
            <SchoolCard school={s} owned={owned} openUploadModal={handleOpenUploadModal} />
          </Col>
        ))}
      </Row>
      <Modal
        title="Upload Display Picture"
        visible={uploadModalVisible}
        confirmLoading={uploading}
        okText="Upload"
        onOk={handleUpload}
        onCancel={() => setUploadModalVisible(false)}
      >
        <Upload.Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag image to this area to upload</p>
          <p className="ant-upload-hint">
            Support for a single upload. Strictly prohibit from uploading company data or other band files
          </p>
        </Upload.Dragger>
      </Modal>
    </StyledCardList>
  )
}

export default view(CardList)
