import React from "react"
import { view } from "react-easy-state"
import { DeleteOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Button, Tooltip } from "antd";

import { renderAsHTML } from "utils/react"

const VideoLectureContent = ({ embedCode, deleting, onDelete, onUppyModalOpen }) => {
  return (
    <div className="form-card">
      <div className="asset-header">
        <h3>
          <VideoCameraOutlined /> Video
        </h3>
        <div>
          <div>
            <Tooltip title="Delete attachment">
              <Button type="danger" shape="circle" onClick={onDelete} loading={deleting}>
                {!deleting && <DeleteOutlined />}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div>{renderAsHTML(embedCode)}</div>
      <div style={{ marginTop: 15 }}>
        {false && (
          <Button type="primary" size="large" onClick={onUppyModalOpen}>
            Update Thumbnail
          </Button>
        )}
      </div>
    </div>
  )
}

export default view(VideoLectureContent)
