import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { view } from "react-easy-state"
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Tag, Tooltip } from "antd"
import CurriculumSectionTextInlineEditForm from "./CurriculumSectionTextInlineEditForm"
import CurriculumLectureTextInlineEditForm from "./CurriculumLectureTextInlineEditForm"

const CurriculumTextInlineEdit = ({ text, title, tag, resource, pageState }) => {
  const [editMode, setEditMode] = useState(false)

  const enableEditMode = () => {
    setEditMode(true)
  }

  const disableEditMode = () => {
    setEditMode(false)
  }

  const renderTitle = () => {

    if(!editMode) {
      return (
        <div className="normal-mode">
          <span onClick={() => {}}>{title}</span>
          <Tooltip title={`Edit ${resource.type} name`}>
            <EditOutlined onClick={enableEditMode} />
          </Tooltip>
          {tag && <Tag color={tag.color}>{tag.text}</Tag>}
        </div>
      )
    }

    if (resource.type === "section") {
      return (
        <div className="edit-mode">
          <CurriculumSectionTextInlineEditForm
            text={text}
            section={resource}
            pageState={pageState}
            disableEditMode={disableEditMode}
          />
          <Button shape="circle" onClick={disableEditMode}>
            <CloseOutlined />
          </Button>
        </div>
      )
    }
    if (resource.type === "lecture") {
      return (
        <div className="edit-mode">
          <CurriculumLectureTextInlineEditForm
            text={text}
            lecture={resource}
            pageState={pageState}
            disableEditMode={disableEditMode}
          />
          <Button shape="circle" onClick={disableEditMode}>
            <CloseOutlined />
          </Button>
        </div>
      )
    }
    return <React.Fragment></React.Fragment>
  }

  return <div className="inline_edit__wrapper">{renderTitle()}</div>

}

export default withRouter(view(CurriculumTextInlineEdit))
