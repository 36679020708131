import React, { useEffect } from "react"
import { withRouter, Link } from "react-router-dom"
import { view } from "react-easy-state"
import { ArrowLeftOutlined, PlusSquareFilled } from "@ant-design/icons"
import { Row, Col, Button } from "antd"

import ContentLoader from "components/common/ContentLoader"
import StyledLink from "components/common/styled-components/StyledLink"
import StyledMainContent from "components/common/styled-components/StyledMainContent"

import LandingPagesService from "services/admin/landing-pages.service"

import globalStore from "store"

const AdminLandingPagesPage = ({ history }) => {
  const pageState = globalStore.ui.admin.landing_pages.index

  useEffect(() => {
    const pageState = globalStore.ui.admin.landing_pages.index
    const fetchPageData = async () => {
      await LandingPagesService.index({ pageState })
    }
    fetchPageData()
  }, [])

  const renderPageContent = () => {
    const { landing_pages, API_LANDING_PAGES_INDEX_STATUS } = pageState

    if (!API_LANDING_PAGES_INDEX_STATUS) return <ContentLoader />
    if (API_LANDING_PAGES_INDEX_STATUS === "pending") return <ContentLoader />
    if (API_LANDING_PAGES_INDEX_STATUS === "rejected") return <ContentLoader />

    if (!landing_pages) return <ContentLoader />

    return (
      <React.Fragment>
        {landing_pages.map((lp) => (
          <p key={lp.id}>
            <Link to={`/admin/landing-pages/${lp.id}`}>{lp.id}</Link>
          </p>
        ))}
      </React.Fragment>
    )
  }

  return (
    <StyledMainContent containerBgColor="white">
      <Row justify="space-between">
        <Col>
          <StyledLink>
            <Button type="link" onClick={() => history.push("/admin/courses")}>
              <ArrowLeftOutlined /> Go to Courses
            </Button>
          </StyledLink>
        </Col>
        <Col>
          <Button type="primary" icon={<PlusSquareFilled />}>
            New Landing Page
          </Button>
        </Col>
      </Row>
      <div className="container">{renderPageContent()}</div>
    </StyledMainContent>
  )
}

export default withRouter(view(AdminLandingPagesPage))
