import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { view } from "react-easy-state"
import styled from "styled-components"
import AceEditor from "react-ace"
import { ArrowLeftOutlined, ArrowRightOutlined, UserOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import { Row, Col, Button, message, Comment, Avatar, List, Input, Form } from "antd"

// allowed languages
import "brace/mode/html"
import "brace/mode/css"
import "brace/mode/javascript"
import "brace/mode/ruby"
import "brace/mode/php"
import "brace/mode/json"

// allowed theme
import "brace/theme/monokai"

import { Color, BoxShadow } from "constant/theme"
import Header from "components/Header/Index"
import ContentLoading from "components/ContentLoading/Index"
import ContentLoadingError from "components/ContentLoadingError/Index"
import EmptyContent from "components/EmptyContent/Index"
import CourseContentSider from "components/CourseContentSider/Index"
import YoutubeVideoPlayer from "components/VideoPlayer/Youtube"

import { renderAsHTML } from "utils/react"

import globalStore from "store/index"

import LecturesService from "services/student/lectures.service"
import UserLecturesService from "services/student/userLectures.service"
import lectureCommentsServices from "services/student/lectureComments.service"

const { TextArea } = Input

const relativeTime = require("dayjs/plugin/relativeTime")
dayjs.extend(relativeTime)

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`

const StyledMainContent = styled.div`
  padding: 20px 100px;
  & > div.container {
    margin-top: 30px;
    & > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      & > .title {
        color: white;
        z-index: 1;
        font-size: 1.8em;
      }
      & > .action-buttons {
        & > button:nth-of-type(1) {
          // margin-right: 10px;
        }
      }
    }
  }
`
const StyledLectureDetailContainer = styled.div``

const StyledLecturesListContainer = styled.div`
  min-height: 500px;
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
`

const StyledContentCard = styled.div`
  min-height: 30px;
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
  margin-bottom: 30px;
  padding: 20px;
  & > .action-button {
    margin-top: 20px;
    text-align: center;
  }
  & .wistia_embed_initialized {
    width: 100% !important;
  }
  & #code-editor {
    width: 100% !important;
    height: auto !important;
    min-height: 250px !important;
    max-height: 500px !important;
  }
`

const StyledCommentBox = styled.div`
  min-height: 30px;
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
  margin-bottom: 30px;
  padding: 20px;
  & .ant-comment {
    padding: 10px;
    border-radius: 7px;
    background: #f3f3f3;
    margin: 15px 0px;
    box-shadow: 0px 0px 0px #00000012;
    transition: all 0.5s;
    &:hover {
      box-shadow: 0px 3px 0px #00000012;
    }
    & .ant-comment-content-author-name {
      font-weight: bold;
    }
    & .ant-comment-content-author-time {
      color: #808080;
    }
  }
`

const StyledChildComment = styled.div`
  margin-left: 50px;
`

const StyledLink = styled.div`
  position: relative;
  & > button {
    padding: 0px;
    color: white;
    &:hover {
      color: white;
    }
  }
`

const StyledCenterItem = styled.div`
  margin: 20px 0px;
  text-align: center;
`

const CommentList = ({
  comments,
  replyCommentId,
  submitting,
  commentValue,
  onReplyClick,
  onChange,
  onSubmitComment,
}) => {
  const formattedComments = comments.map((c) => {
    let parentId = c.id

    if (c.ancestry) {
      parentId = c.ancestry.split("/").splice(-1)[0]
    }

    return {
      actions: [
        <span key="comment-basic-reply-to" onClick={() => onReplyClick(c.id, parentId)}>
          Reply
        </span>,
      ],
      commentId: c.id,
      ancestry: c.ancestry,
      content: c.text,
      avatar: c.user.display_picture_url || <Avatar icon={<UserOutlined />} />,
      author: c.user.name,
      datetime: dayjs().to(dayjs(c.created_at)),
    }
  })

  return (
    <List
      dataSource={formattedComments}
      header={null}
      itemLayout="horizontal"
      renderItem={(props) => {
        const { commentId, ancestry, ...commentProps } = props

        const renderEditor = () => {
          if (!replyCommentId || replyCommentId !== commentId) {
            return null
          }

          return (
            <Comment
              avatar={<Avatar icon={<UserOutlined />} />}
              content={
                <Editor
                  buttonText="Reply"
                  onChange={onChange}
                  onSubmit={onSubmitComment}
                  submitting={submitting}
                  value={commentValue}
                />
              }
            />
          )
        }

        if (ancestry) {
          return (
            <StyledChildComment>
              <Comment {...commentProps} />
              {renderEditor()}
            </StyledChildComment>
          )
        }

        return (
          <React.Fragment>
            <Comment {...commentProps} />
            {renderEditor()}
          </React.Fragment>
        )
      }}
    />
  )
}

const Editor = ({ buttonText = "Add Comment", onChange, onSubmit, submitting, value }) => (
  <div>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        {buttonText}
      </Button>
    </Form.Item>
  </div>
)

const LecturesShowPage = ({ history, match }) => {
  const { courseId, lectureId } = match.params
  const pageState = globalStore.ui.student.lectures.show

  const [completeButtonLoading, setCompleteButtonLoading] = useState(false)
  const [comments, setComments] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [commentValue, setCommentValue] = useState("")
  const [commentsMeta, setCommentsMeta] = useState({})
  const [loadingMoreComments, setLoadingMoreComments] = useState(false)
  const [replyCommentId, setReplyCommentId] = useState(null)
  const [replyParentId, setReplyParentId] = useState(null)

  useEffect(() => {
    const fetchPageData = async () => {
      await LecturesService.show({ pageState, courseId, lectureId })

      const { lecture_comments, lecture_comments_meta, lectureErrors, courseErrors } = pageState

      if (courseErrors && courseErrors.length > 0) {
        const errorMsg = typeof courseErrors[0] === "string" ? courseErrors[0] : "Course not found"
        message.error(errorMsg)
        history.push(`/courses`)
        return
      }

      if (lectureErrors && lectureErrors.length > 0) {
        const errorMsg = typeof lectureErrors[0] === "string" ? lectureErrors[0] : "Lecture not found"
        message.error(errorMsg)
        history.push(`/courses/${courseId}`)
        return
      }

      setComments(lecture_comments || [])
      setCommentsMeta(lecture_comments_meta || {})
    }

    fetchPageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lectureId])

  const handleCompleteAndContinue = async () => {
    setCompleteButtonLoading(true)

    await UserLecturesService.complete({ pageState, lectureId })

    setCompleteButtonLoading(false)

    const { lecture, next_lecture } = pageState

    globalStore.ui.student.lectures.show.lecture = lecture

    if (next_lecture) {
      history.push(`/courses/${courseId}/lectures/${next_lecture.id}`)
      return
    }

    history.push(`/courses/${courseId}`)

    return
  }

  const handleSubmitComment = async () => {
    if (!commentValue) {
      return
    }

    setSubmitting(true)

    const postData = {
      lecture_comment: {
        parent_id: replyParentId || null,
        text: commentValue,
      },
    }

    await lectureCommentsServices.create({ pageState, lectureId, values: postData })

    setSubmitting(false)

    const { lecture_comments, lecture_comments_meta } = pageState

    // if (lecture_comments.length > comments.length) {
    message.success("Comment sent successfully.")
    setCommentValue("")
    setReplyCommentId(null)
    setReplyParentId(null)
    setComments(lecture_comments)
    setCommentsMeta(lecture_comments_meta || {})
    // return
    // }

    // message.error("Failed to send comment")
  }

  const handleLoadMoreComments = async () => {
    setLoadingMoreComments(true)

    await lectureCommentsServices.index({ pageState, lectureId, nextPage: commentsMeta.next_page })

    setLoadingMoreComments(false)

    const { lecture_comments, lecture_comments_meta, lectureCommentsErrors } = pageState

    if (lectureCommentsErrors && lectureCommentsErrors.length > 0) {
      message.error("Failed to load comments")
      return
    }

    setComments([...comments, ...lecture_comments])
    setCommentsMeta(lecture_comments_meta || {})
  }

  const handleChange = (e) => {
    setCommentValue(e.target.value)
  }

  const handleReplyClick = (commentId, parentId) => {
    setReplyCommentId(commentId)
    setReplyParentId(parentId)
  }

  const getMainContentComponent = ({ status, lecture, course }) => {
    return (
      <div className="container">
        <div className="header" style={{ opacity: status === "success" ? 1 : 0 }}>
          <div className="title">{lecture && lecture.name}</div>
          <div className="action-buttons">
            <Button type="primary" size="large" onClick={handleCompleteAndContinue} loading={completeButtonLoading}>
              {lecture && lecture.completed ? "Continue" : "Complete and Continue"} <ArrowRightOutlined />
            </Button>
          </div>
        </div>

        <Row gutter={20}>
          <Col xs={{ span: 24 }} lg={{ span: 16 }}>
            <StyledLectureDetailContainer>
              {status === "loading" && <ContentLoading title="Loading lecture's contents..." />}
              {status === "error" && <ContentLoadingError />}
              {status === "success" && renderLectureContents({ lecture })}
            </StyledLectureDetailContainer>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 8 }}>
            <StyledLecturesListContainer>
              <CourseContentSider sections={course && course.sections} />
            </StyledLecturesListContainer>
          </Col>
        </Row>
      </div>
    )
  }

  const renderRootCommentEditor = () => {
    if (replyCommentId) {
      return (
        <StyledCenterItem>
          <Button
            type="primary"
            onClick={() => {
              setReplyCommentId(null)
              setReplyParentId(null)
              setCommentValue("")
            }}
          >
            Add Comment
          </Button>
        </StyledCenterItem>
      )
    }

    return (
      <Comment
        avatar={<Avatar icon="user" />}
        content={
          <Editor onChange={handleChange} onSubmit={handleSubmitComment} submitting={submitting} value={commentValue} />
        }
      />
    )
  }

  const renderLectureContents = ({ lecture }) => {
    if (!lecture) return null

    const { contents, comments_allowed } = lecture

    if (!contents || contents.length === 0) {
      return <EmptyContent />
    }

    const lecturesJSX = contents.map((content) => {
      if (!content.details) return null

      if (content.content_type === "video" && content.details.wistia_response) {
        return (
          <StyledContentCard key={content.id}>
            {/* <h2>Video</h2> */}
            {renderAsHTML(content.details.wistia_response.html)}
          </StyledContentCard>
        )
      }
      if (content.content_type === "video" && content.details.youtube_url) {
        return (
          <StyledContentCard key={content.id}>
            {/* <h2>Video</h2> */}
            <YoutubeVideoPlayer url={content.details.youtube_url} />
          </StyledContentCard>
        )
      }
      if (content.content_type === "note") {
        return (
          <StyledContentCard key={content.id}>
            {/* <h2>Note</h2> */}
            {renderAsHTML(content.details.html)}
          </StyledContentCard>
        )
      }
      if (content.content_type === "code") {
        return (
          <StyledContentCard key={content.id}>
            {/* <h2>Code</h2> */}
            <AceEditor
              mode={content.details.language}
              readOnly={true}
              theme="monokai"
              name="code-editor"
              value={content.details.code}
            />
          </StyledContentCard>
        )
      }
      return null
    })

    return (
      <React.Fragment>
        {lecturesJSX}
        {comments_allowed && (
          <StyledCommentBox>
            <h2>Comments ({`${comments.length}`})</h2>
            {renderRootCommentEditor()}
            <div className="comments">
              {comments.length > 0 && (
                <CommentList
                  comments={comments}
                  submitting={submitting}
                  commentValue={commentValue}
                  replyCommentId={replyCommentId}
                  onReplyClick={handleReplyClick}
                  onChange={handleChange}
                  onSubmitComment={handleSubmitComment}
                />
              )}
              {commentsMeta.next_page ? (
                <StyledCenterItem>
                  <Button loading={loadingMoreComments} onClick={handleLoadMoreComments}>
                    Load more comments
                  </Button>
                </StyledCenterItem>
              ) : (
                <StyledCenterItem>
                  <p>-- No more comments --</p>
                </StyledCenterItem>
              )}
            </div>
          </StyledCommentBox>
        )}
      </React.Fragment>
    )
  }

  const renderMainContent = () => {
    const { lecture, course, lectureErrors, API_LECTURES_SHOW_STATUS } = pageState

    if (!API_LECTURES_SHOW_STATUS) {
      return getMainContentComponent({ status: "loading", lecture, course })
    }
    if (API_LECTURES_SHOW_STATUS === "pending") {
      return getMainContentComponent({ status: "loading", lecture, course })
    }
    if (API_LECTURES_SHOW_STATUS === "rejected") {
      return getMainContentComponent({ status: "error" })
    }

    if (lectureErrors && lectureErrors.length > 0) {
      const errorMessage = typeof lectureErrors[0] === "string" ? lectureErrors[0] : "Oops!! something went wrong"

      message.error(errorMessage)
      history.push(`/courses/${courseId}`)
      return

      // return this.getMainContentComponent({ status: "error" })
    }

    return getMainContentComponent({ status: "success", lecture, course })
  }

  return (
    <StyledPage>
      <Header />
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        <StyledMainContent>
          <Row>
            <StyledLink>
              <Button type="link" onClick={() => history.push(`/courses/${courseId}`)}>
                <ArrowLeftOutlined /> Go to Course Page
              </Button>
            </StyledLink>
          </Row>
          {renderMainContent()}
        </StyledMainContent>
      </StyledPageContent>
    </StyledPage>
  )
}

export default withRouter(view(LecturesShowPage))
