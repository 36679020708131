import { fetchFromLocalStorage, storeInLocalStorage } from "store/localStorage"

const TOKEN_KEY = "token"
// const USER_KEY = "user"

export const getToken = () => {
  return fetchFromLocalStorage({
    key: TOKEN_KEY,
  })
}

export const setToken = (token) => {
  return storeInLocalStorage({
    key: TOKEN_KEY,
    value: token,
  })
}

// export const getUser = () => {
//   return fetchFromLocalStorage({
//     key: USER_KEY,
//   })
// }

// export const setUser = (data) => {
//   return storeInLocalStorage({
//     key: USER_KEY,
//     value: data,
//   })
// }

export const isLoggedIn = () => {
  const token = getToken()

  if (token) return true
  return false
}

export const logout = () => {
  storeInLocalStorage({ key: TOKEN_KEY, value: null })
  storeInLocalStorage({ key: "user", value: null })

  // console.log("logout...")

  // if (!URLHelper.isSecureSubDomain()) {
  //   window.location = URLHelper.getSecureURL("/logout")
  // }

  // Modal.confirm({
  //   title: "Do you want to logout?",
  //   content: "",
  //   onOk() {
  //     storeInLocalStorage({ key: TOKEN_KEY, value: null })
  //     storeInLocalStorage({ key: "user", value: null })
  //     console.log("logout...")
  //     window.location.href = "/"
  //   },
  //   onCancel() {},
  // })
}
