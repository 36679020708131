
import styled from "styled-components"

const StyledLink = styled.div`
  position: relative;
  & > button {
    padding: 0px;
    color: white;
    &:hover {
      color: white;
    }
  }
`

export default StyledLink
