import React from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import { Input, Button, message, Form } from "antd"

import CoursesService from "services/admin/courses.service"
import globalStore from "store/index"

const NewCourseForm = ({ history }) => {
  const pageState = globalStore.ui.courses.new
  const buttonLoading = pageState.API_COURSES_CREATE_STATUS === "pending"

  const handleFinish = async (values) => {
    await CoursesService.create({ pageState, values })

    if (pageState.API_COURSES_CREATE_STATUS === "fulfilled") {
      message.success("New course has been created")
      history.push("/admin/courses")
    }
  }

  return (
    <Form layout="vertical" onFinish={handleFinish}>
      <Form.Item label="Course Title" name="name" rules={[{ required: true, message: "Please enter course title!" }]}>
        <Input size="large" placeholder="e.g. 'Javascript for Beginners-Free'" />
      </Form.Item>
      <Form.Item
        label="Course Subtitle"
        name="subtitle"
        rules={[{ required: true, message: "Please enter subtitle!" }]}
      >
        <Input
          size="large"
          placeholder="e.g. 'The first step to learn JS curated list of projects that help you to understand basics of JavaScript'"
        />
      </Form.Item>
      <div className="align-right">
        <Button size="large" type="primary" htmlType="submit" loading={buttonLoading}>
          {buttonLoading ? "Creating course..." : "Create course"}
        </Button>
      </div>
    </Form>
  )
}

export default withRouter(view(NewCourseForm))
