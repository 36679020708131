import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { Switch, Row, Col, Button, Divider, Alert, message } from "antd"

import globalStore from "store/index"
import { BoxShadow, Color } from "constant/theme"
import SubscriptionsService from "services/admin/subscriptions.service"

const StyledPlanPageContent = styled.div`
  text-align: center;
`

const StyledBillingSwitchOn = styled.div`
  margin: 50px 0px 0px 0px;
  & > button {
    margin: 0px 10px;
  }
  & > span {
    transition: all 0.5s;
  }
  & > .selected {
    font-weight: bold;
  }
`
const StyledPlanCards = styled.div`
  margin: 20px 0px 50px 0px;
  padding: 30px;
`

const StyledPlanCard = styled.div`
  width: 100%;
  min-height: 400px;
  background: #8800ff0d;
  box-shadow: ${(props) => (props.highlighted ? BoxShadow.card.normal : "")};
  border-radius: 7px;
  padding: 20px;
  border: 3px solid transparent;
  border-color: ${(props) => (props.highlighted ? Color.brandColor : "tranparent")};
  transition: all 0.5s;
  &:hover {
    // border-color: ${Color.brandColor};
    box-shadow: ${BoxShadow.card.normal};
  }
  & > h3.type {
    font-size: 1.2em;
    margin: 30px 0px;
  }
  & > h3.price {
    font-size: 2em;
  }
  & > button.upgrade {
    margin: 20px 0px;
  }
  & .features {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > strong {
      margin-bottom: 10px;
    }
    & > span {
      margin-bottom: 5px;
    }
  }
`

const StyledCapitalizedText = styled.span`
  text-transform: capitalize;
`

const currencyMap = {
  inr: "₹",
}

const SchoolPlans = ({ plans = [], currentPlan = {} }) => {
  const pageState = globalStore.ui.admin.settings.plans.index
  const recommendedPlanId = 3
  const selectedCurrency = "inr"
  const [activePlanId, setActivePlanId] = useState(currentPlan.id)
  const [billingFrequency, setBillingFrequency] = useState("monthly")
  const [formattedPlans, setFormattedPlans] = useState([])
  const [selectedPlanId, setSelectedPlanId] = useState(null)
  const [planUpgrading, setPlanUpgrading] = useState(false)

  useEffect(() => {
    setFormattedPlans(getCardData(plans))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans])

  const findPlanPrice = (planId) => {
    const plan = plans.find((p) => p.id === planId)

    if (!plan) return 0

    const priceObj = plan.prices.find((price) => price.currency === selectedCurrency)

    return priceObj ? priceObj[`${billingFrequency}_price`] : 0
  }

  const getCardData = (plans) => {
    // const paidPlans = plans.filter((p) => !p.free)

    return plans.map((p) => {
      const { id, name, free, prices } = p

      const features = p.plan_features
        .filter((pf) => pf.display)
        .map((pf, i) => {
          const {
            feature: { unit, value_datatype, display_name },
            value,
          } = pf

          if (!pf.feature) return null

          return (
            renderFeatureValue({
              keyIndex: i,
              value,
              unit: unit,
              valueDataType: value_datatype,
              displayName: display_name,
            }) || ""
          )
        })

      return {
        id,
        name,
        free,
        prices,
        features,
      }
    })
  }

  const handleBillingSwitchOnChange = (switcOn) => {
    if (switcOn) {
      setBillingFrequency("annual")
      return
    }
    setBillingFrequency("monthly")
  }

  const handleUpgrade = async (planId) => {
    if (!planId) return

    const planPrice = findPlanPrice(planId)

    if (!planPrice) {
      message.error("Oops!! something went wrong. Please try after sometime.")
      console.error("Plan Price is not valid")
      return
    }

    setSelectedPlanId(planId)
    setPlanUpgrading(true)

    const postData = {
      frequency: billingFrequency,
      currency: selectedCurrency,
    }

    const { current_plan } = pageState

    console.log("pageState", pageState)

    if (!current_plan || current_plan.free) {
      subscribePlan({ planId, postData, planPrice })
      return
    }

    upgradePlan({ planId, postData, planPrice })
  }

  const subscribePlan = async ({ planId, postData, planPrice }) => {
    await SubscriptionsService.create({ pageState, planId, values: postData })

    const { subscription } = pageState

    if (!subscription || !subscription.external_id) {
      message.error("Oops!! something went wrong. Please try after sometime.")
      return
    }

    const razorpayOptions = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      subscription_id: subscription.external_id,
      amount: planPrice * 100, // 2000 paise = INR 20, amount in paisa
      name: "CourseMango",
      description: "CourseMango's plan subscription",
      handler: async (response) => {
        message.destroy()

        const razorpayPaymentId = response.razorpay_payment_id

        if (!razorpayPaymentId) {
          message.error("Oops!! something went wrong")
          setPlanUpgrading(false)
          return
        }

        const pageState = globalStore.ui.admin.settings.plans.index

        await SubscriptionsService.activate({ pageState, planId, values: response })

        setPlanUpgrading(false)

        const { subscription } = pageState

        if (!subscription || !subscription.cm_plan_id) {
          message.error("Oops!!, something went wrong. Please visit contact us page if money has been deducted.")
          return
        }

        setActivePlanId(subscription.cm_plan_id)

        message.success("Plan updated successfully")
        return
      },
    }

    const razorpay = new window.Razorpay(razorpayOptions)

    razorpay.on("payment.error", ({ error }) => {
      console.log("errors", error)
      message.destroy()
      message.error(error.description)
      setPlanUpgrading(false)
      return
    })
    razorpay.open()
  }

  const upgradePlan = async ({ planId, postData }) => {
    await SubscriptionsService.changePlan({ pageState, planId, values: postData })

    setPlanUpgrading(false)

    const { subscription } = pageState

    if (!subscription || !subscription.cm_plan_id) {
      message.error("Oops!!, something went wrong. Please visit contact us page if money has been deducted.")
      return
    }

    setActivePlanId(subscription.cm_plan_id)

    message.success("Plan updated successfully")
    return
  }

  const renderFeatureValue = ({ keyIndex, value, unit, valueDataType, displayName }) => {
    if (unit === "none") {
      if (value) {
        return (
          <React.Fragment key={keyIndex}>
            <CheckCircleOutlined style={{ color: Color.right }} /> {displayName}
          </React.Fragment>
        )
      }
      return (
        <React.Fragment key={keyIndex}>
          <CloseCircleOutlined style={{ color: Color.wrong }} /> {displayName}
        </React.Fragment>
      )
    }

    if (unit === "number") {
      return (
        <React.Fragment key={keyIndex}>
          <StyledCapitalizedText>{value}</StyledCapitalizedText> {displayName}
        </React.Fragment>
      )
    }

    if (unit === "percentage") {
      return (
        <React.Fragment key={keyIndex}>
          <StyledCapitalizedText>{value}%</StyledCapitalizedText> {displayName}
        </React.Fragment>
      )
    }
  }

  const renderUpgradeButton = ({ planId }) => {
    if (activePlanId === planId) {
      return (
        <Button className="upgrade active-plan" type="primary" disabled={true}>
          Currently active
        </Button>
      )
    }

    return (
      <Button
        className="upgrade"
        type="primary"
        onClick={() => handleUpgrade(planId)}
        disabled={planUpgrading}
        loading={selectedPlanId === planId && planUpgrading}
      >
        Upgrade
      </Button>
    )
  }

  const renderPlanPrice = (prices) => {
    if (!prices || !prices[0]) return null

    const currency = currencyMap[prices[0].currency]
    const price = billingFrequency === "monthly" ? prices[0].monthly_price : prices[0].annual_price

    return (
      <React.Fragment>
        <span className="currency">{currency}</span> {Math.round(price)} /-
      </React.Fragment>
    )
  }

  const renderPlanCards = () => {
    return (
      <Row gutter={30}>
        {formattedPlans
          .filter((p) => !p.free)
          .map((fp) => {
            return (
              <Col span={8} key={`plan-${fp.id}`}>
                <StyledPlanCard highlighted={fp.id === recommendedPlanId}>
                  <h3 className="type">{fp.name}</h3>
                  <h3 className="price">{renderPlanPrice(fp.prices)}</h3>
                  <p>per month</p>
                  {renderUpgradeButton({ planId: fp.id })}
                  <Divider />
                  <div className="features">
                    <strong>Features</strong>
                    {fp.features.map((f, i) => (
                      <span key={i}>{f}</span>
                    ))}
                  </div>
                </StyledPlanCard>
              </Col>
            )
          })}
      </Row>
    )
  }

  const renderFreePlan = () => {
    const freePlan = formattedPlans.find((fp) => fp.free)

    if (!freePlan) return

    if (activePlanId) return

    const features = freePlan.features.map((f, i) => (
      <p style={{ marginBottom: 0 }} key={i}>
        {f}
      </p>
    ))

    return <Alert message="Free plan is activated" description={features} type="info" showIcon />
  }

  return (
    <StyledPlanPageContent>
      <div style={{ textAlign: "left", marginBottom: 40 }}>{renderFreePlan()}</div>
      <h1>Find the perfect plan for your school</h1>
      <p>Select the perfect to your needs, Is always flexible to grow</p>
      <StyledBillingSwitchOn>
        <span className={billingFrequency === "monthly" ? "selected" : ""}>Monthly Billing</span>
        <Switch onChange={handleBillingSwitchOnChange} />
        <span className={billingFrequency === "annual" ? "selected" : ""}>Yearly Billing</span>
      </StyledBillingSwitchOn>
      <StyledPlanCards>{renderPlanCards()}</StyledPlanCards>
    </StyledPlanPageContent>
  )
}

export default SchoolPlans
