import React from "react"
import styled from "styled-components"
import { Row, Col } from "antd"

import CourseCard from "components/CourseCard/Index"

const StyledCardList = styled.div`
  position: relative;
  padding: 50px 40px;
  & > h1 {
    margin: 0px 0px 20px 0px;
    text-transform: uppercase;
    color: ${(props) => props.color || "black"};
  }
`

const CardList = ({ courses = [], title, itemsPerRow = 4, headerColor, enrolled = false }) => {
  const validItemsPerRow = [2, 3, 4, 6]

  const span = validItemsPerRow.includes(itemsPerRow) ? 24 / itemsPerRow : 8

  return (
    <StyledCardList color={headerColor}>
      {title && <h1>{title}</h1>}
      <Row type="flex" justify="start" align="top" gutter={20}>
        {courses.map((c, i) => (
          <Col span={span} key={i}>
            <CourseCard course={c} enrolled={enrolled} />
          </Col>
        ))}
      </Row>
    </StyledCardList>
  )
}

export default CardList
