import React, { useEffect } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
// import { Icon as LegacyIcon } from "@ant-design/compatible"
import {
  LeftOutlined,
  RightOutlined,
  ReadOutlined,
  SnippetsOutlined,
  UserOutlined,
  CreditCardOutlined,
  HomeOutlined,
  QuestionOutlined,
  BankOutlined,
} from "@ant-design/icons"
import { Avatar, Button } from "antd"

import ContentLoading from "components/ContentLoading/Index"

import globalStore from "store"
import SchoolsService from "services/admin/schools.service"

import * as URLHelper from "helpers/url.helper"

import { Color, BoxShadow } from "constant/theme"

const StyledPageSider = styled.div`
  position: fixed;
  width: 280px;
  left: 20px;
  top: 20px;
  min-height: calc(100vh - 40px);
  background: white;
  box-shadow: ${BoxShadow.card.normal};
  border-radius: 7px;
`

const StyledSiderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  position: absolute;
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
  color: ${Color.brandColor};
  & > .school-name {
    padding: 20px 10px;
    text-align: center;
  }
  & > .ant-avatar {
    margin: 20px 0px 20px 0px;
    background: ${Color.brandColor};
  }
  & > h2,
  & > p {
    font-size: 0.8em;
    color: black;
    font-weight: normal;
  }
`
const StyledSiderMenu = styled.ul`
  position: relative;
  margin: ${(props) => (props.submenu ? "0px" : "30px")} 0px 0px 0px;
  padding: 0px;
  list-style-type: none;
  overflow-x: hidden;
  width: 100%;
  & > li {
    margin-bottom: -1px;
    border: 1px solid whitesmoke;
    & > button {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: black;
      text-align: left;
      & > span > span.anticon {
        margin-right: 10px;
      }
    }
  }
  & > li.item-selected {
    & > button {
      color: ${Color.brandColor};
    }
  }
`

const StyledSubMenuContainer = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background: white;
  transition: all 0.5s;
  right: ${(props) => (props.show ? "0px" : "-280px")};
`

const menuItems = [
  {
    dataIndex: "courses",
    icon: <ReadOutlined />,
    title: "Courses",
    path: "/admin/courses",
  },
  {
    dataIndex: "landing-pages",
    icon: <SnippetsOutlined />,
    title: "Landing Pages",
    path: "/admin/landing-pages",
  },
  {
    dataIndex: "profile",
    icon: <UserOutlined />,
    title: "Profile",
    path: "/profile",
  },
  {
    dataIndex: "plan",
    icon: <CreditCardOutlined />,
    title: "Plan",
    path: "/admin/settings/plan",
  },
  {
    dataIndex: "coursemango",
    icon: <HomeOutlined />,
    title: "CourseMango",
    path: "/admin?to=home",
  },
  {
    dataIndex: "help",
    icon: <QuestionOutlined />,
    title: "Help",
    path: "/admin/settings/help",
  },
]

const courseSubmenu = (courseId) => [
  {
    title: "Information",
    path: `/admin/courses/${courseId}/information`,
  },
  {
    title: "Curriculum",
    path: `/admin/courses/${courseId}/curriculum`,
  },
]

const Sider = ({ history }) => {
  const siderState = globalStore.sider

  // Fetch school data if not already fetched
  useEffect(() => {
    const siderState = globalStore.sider

    // Call the API only once
    if (!!siderState.API_SCHOOLS_ME_STATUS) return

    const fetchData = async () => {
      await SchoolsService.me({ pageState: siderState })
    }

    fetchData()
  }, [siderState.school, siderState.API_SCHOOLS_ME_STATUS])

  const courseId = URLHelper.getAdminCourseId()

  useEffect(() => {
    if (!courseId) return

    siderState.selectedDataIndex = "courses"
    siderState.selectedItemChildren = courseSubmenu(courseId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId])

  useEffect(() => {
    const { pathname } = history.location

    const menuItem = menuItems.find((mi) => mi.path === pathname)

    if (!menuItem) return

    siderState.selectedDataIndex = menuItem.dataIndex

    if (menuItem.dataIndex !== "courses") {
      siderState.selectedItemChildren = menuItem.children || []
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showItemSubMenuOrRedirect = (e, menuItem) => {
    if (!menuItem) return

    siderState.selectedDataIndex = menuItem.dataIndex

    if (menuItem.children && menuItem.children.length > 0) {
      siderState.selectedItemChildren = menuItem.children
      return
    }

    // FIXME: Check if this works for other (non-courses) submenu
    siderState.selectedItemChildren = []
    history.push(menuItem.path)
  }

  const renderSchoolAvatar = () => {
    if (school.display_picture_url) {
      return <Avatar shape="square" size={80} src={school.display_picture_url} />
    }

    return <Avatar shape="square" size={80} icon={<BankOutlined />} />
  }

  const { school, API_SCHOOLS_ME_STATUS, selectedDataIndex, selectedItemChildren } = siderState

  const selectedMenuItem = menuItems.find((mi) => mi.dataIndex === selectedDataIndex)

  const renderSider = () => {
    const loading = API_SCHOOLS_ME_STATUS !== "fulfilled" || !school

    if (loading) return <ContentLoading />

    return (
      <StyledSiderContent>
        <div className="school-name">{school.name}</div>
        {renderSchoolAvatar()}
        <StyledSiderMenu>
          {menuItems.map((mi, index) => {
            // console.log("mi", mi)
            // console.log("selectedDataIndex", selectedDataIndex)
            // console.log("selectedItemChildren", selectedItemChildren)

            return (
              <li
                key={mi.dataIndex}
                id={mi.dataIndex}
                className={mi.path === history.location.pathname ? "item-selected" : ""}
              >
                <Button type="link" onClick={(e) => showItemSubMenuOrRedirect(e, mi)}>
                  <span>
                    {mi.icon} {mi.title}
                  </span>
                  <span>
                    {selectedDataIndex === mi.dataIndex && selectedItemChildren.length > 0 && <RightOutlined />}
                  </span>
                </Button>
              </li>
            )
          })}
          <StyledSubMenuContainer show={selectedItemChildren.length > 0}>
            {selectedItemChildren.length > 0 && (
              <StyledSiderMenu submenu={true}>
                <li key="submenu-parent">
                  <Button
                    type="link"
                    onClick={() => {
                      siderState.selectedItemChildren = []
                    }}
                  >
                    <span>
                      <LeftOutlined /> {selectedMenuItem && selectedMenuItem.title}
                    </span>
                  </Button>
                </li>
                {selectedItemChildren.map((smi, i) => (
                  <li key={`submenu-${i}`}>
                    <Button type="link" style={{ marginLeft: 20 }} onClick={(e) => showItemSubMenuOrRedirect(e, smi)}>
                      <span>
                        {smi.icon} {smi.title}
                      </span>
                    </Button>
                    {smi.children && <RightOutlined />}
                  </li>
                ))}
              </StyledSiderMenu>
            )}
          </StyledSubMenuContainer>
        </StyledSiderMenu>
      </StyledSiderContent>
    )
  }

  return <StyledPageSider>{renderSider()}</StyledPageSider>
}

export default withRouter(view(Sider))
