import { API, generic } from "api"

export default class SubscriptionsService {
  static create = async ({ pageState, planId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_CM_SUBSCRIPTIONS_CREATE_STATUS",
      stateDataKey: ["subscription"],
      stateErrorKey: ["subscriptionErrors"],
      apiEndpoint: API.admin.cm.subscriptions.create,
      apiUrlReplacements: { planId },
      apiData: { ...values },
      errorMessage: "Subscription could not be created",
    })
  }

  static activate = async ({ pageState, planId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_CM_SUBSCRIPTIONS_ACTIVATE_STATUS",
      stateDataKey: ["subscription"],
      stateErrorKey: ["subscriptionErrors"],
      apiEndpoint: API.admin.cm.subscriptions.activate,
      apiUrlReplacements: { planId },
      apiData: { ...values },
      errorMessage: "Subscription could not be activated",
    })
  }

  static changePlan = async ({ pageState, planId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ADMIN_CM_SUBSCRIPTIONS_CHANGE_PLAN_STATUS",
      stateDataKey: ["subscription"],
      stateErrorKey: ["subscriptionErrors"],
      apiEndpoint: API.admin.cm.subscriptions.change_plan,
      apiUrlReplacements: { planId },
      apiData: { ...values },
      errorMessage: "Subscription could not be changed",
    })
  }
}
