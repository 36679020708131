import React from "react"
import { view } from "react-easy-state"
import styled from "styled-components"
import { Button } from "antd"

const StyledFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: inherit;
  & > .footer-links {
    & > ul {
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      display: inline-flex;
    }
  }
`

const Footer = (props) => {

  const renderDefaultFooter = () => {
    return <StyledFooter>
      <div className="copyright">
        <span>Copyright &copy; {new Date().getFullYear()} CoralMango Solutions Private Limited</span>
      </div>
      <div className="footer-links">
        <ul>
          <li><Button type="link" href="/privacy-policy">Privacy Policy</Button></li>
          <li><Button type="link" href="cancellation-refund-policy">Cancellation & Refund Policy</Button></li>
          <li><Button type="link" href="terms-and-conditions">Terms & Conditions</Button></li>
        </ul>
      </div>
    </StyledFooter>
  }

  return <div className="page-footer">
    {props.content || renderDefaultFooter()}
  </div>
}

export default view(Footer)
