import styled from "styled-components"

const StyledCourseInformationCard = styled.div`
  margin: 0px 0px 50px 0px;
  & > .form-wrapper {
    & .form-info > h3 {
      font-size: 1.5em;
      color: ${props => props.topCard ? "white" : "black"}
    }
    & .form-info > p {
      margin: 10px 0px 15px 0px;
      color: ${props => props.topCard ? "white" : "black"}
    }
  }
`

export default StyledCourseInformationCard