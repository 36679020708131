import React, { useEffect } from "react"
import plyr from "plyr"
import "plyr/dist/plyr.css"

const YoutubeVideoPlayer = ({ url }) => {
  // let players = null

  useEffect(() => {
    // players = plyr.setup("#plyr-player")
    plyr.setup("#plyr-player")
  }, [])

  const youtube_parser = (url) => {
    if (!url) return
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url.match(regExp)
    return match && match[7].length === 11 ? match[7] : false
  }

  return (
    <div className="video-player">
      <div id="plyr-player" data-plyr-provider="youtube" data-plyr-embed-id={youtube_parser(url)}></div>
    </div>
  )
}

export default YoutubeVideoPlayer
