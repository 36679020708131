import { API, generic } from "api"

export default class ContentsService {
  static create = async ({ pageState, lectureId, values, formData }) => {
    if (!lectureId) return
    await generic({
      pageState,
      stateApiStatusKey: "API_CONTENTS_CREATE_STATUS",
      stateDataKey: ["lecture"],
      stateErrorKey: [`lectureErrors`],
      apiEndpoint: API.admin.contents.create,
      apiUrlReplacements: { lectureId },
      apiData: formData ? formData : { ...values },
      errorMessage: "Content could not be created",
    })
  }

  static delete = async ({ pageState, contentId }) => {
    if (!contentId) return
    await generic({
      pageState,
      stateApiStatusKey: "API_CONTENTS_DELETE_STATUS",
      stateDataKey: ["lecture"],
      stateErrorKey: ["lectureErrors"],
      apiEndpoint: API.admin.contents.delete,
      apiUrlReplacements: { contentId },
      apiData: {},
      errorMessage: "Content could not be deleted",
    })
  }

  static reorder = async ({ contentId, pageState, stateApiStatusKey = "API_CONTENTS_REORDER_STATUS", values }) => {
    await generic({
      pageState,
      stateApiStatusKey,
      stateDataKey: "lecture",
      stateErrorKey: "lectureErrors",
      apiEndpoint: API.admin.contents.reorder,
      apiUrlReplacements: { contentId },
      apiData: { ...values },
      errorMessage: "Lecture could not be fetched.",
    })
  }
}
