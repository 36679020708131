import React from "react"
import GrapesJS from "grapesjs"

import mjml from "grapesjs-mjml"
import newsletter from "grapesjs-preset-newsletter"
import webpage from "grapesjs-preset-webpage"

const presets = {
  webpage,
  newsletter,
  mjml,
}

class Editor extends React.Component {
  componentDidMount() {
    const {
      onInit,
      id,
      blockManager,
      styleManager,
      storageManager,
      width,
      height,
      plugins: propPlugins,
      presetType,
      components,
      style,
    } = this.props

    console.log("Editor: props:", this.props)

    const editor = GrapesJS.init({
      container: `#${id}`,
      fromElement: true,
      blockManager,
      styleManager,
      storageManager,
      width,
      height,
      plugins: [presets[presetType], ...propPlugins],
      components,
      style,
      log: ["debug", "info", "warning", "error"],
    })

    if (typeof onInit === "function") {
      onInit(editor)
    }

    this.setState({
      editor,
    })
  }

  componentWillUnmount() {
    const { editor } = this.state
    const { onDestroy, id } = this.props
    if (editor) {
      if (typeof onDestroy === "function") {
        onDestroy(editor)
      }
      GrapesJS.editors = GrapesJS.editors.filter((e) => e !== editor)
      editor.destroy()
      if (document) {
        const container = document.getElementById(id)
        if (container) {
          container.innerHTML = ""
        }
      }
    }
  }

  render() {
    const { children, id } = this.props

    return <div id={id}>{children}</div>
  }
}

Editor.defaultProps = {
  id: "grapesjs-react-editor",
  presetType: "newsletter",
  plugins: [],
  blocks: [],
  blockManager: {},
  storageManager: {},
  styleManager: {},
  width: "auto",
  height: "100vh",
  components: [],
  style: [],
}

export default Editor
