import React from "react"
import { Button } from "antd"
import { Link } from "react-router-dom"
import { view } from "react-easy-state"
import { message } from "antd"

import globalStore from "store/index"
import CoursesService from "services/admin/courses.service"
// import SchoolPlansService from "services/admin/school-plans.service"
import StyledCourseInformationCard from "components/common/styled-components/StyledCourseInformationCard"

const InformationPublish = ({ topCard }) => {
  const pageState = globalStore.ui.courses.information
  const { course } = pageState

  const handleClick = async () => {
    if (!course) return

    if (course.published) {
      await CoursesService.unpublish({ courseId: course.id, pageState })
      const { courseUnpublishErrors } = pageState
      if (courseUnpublishErrors && courseUnpublishErrors.length > 0) {
        message.error("Course could not be unpublished")
        return
      }
      message.success("Course Unpublished")
      return
    }

    await CoursesService.publish({ courseId: course.id, pageState })
    const { coursePublishErrors } = pageState
    if (coursePublishErrors && coursePublishErrors.length > 0) {
      message.error("Course could not be published. " + coursePublishErrors[0])
      return
    }

    message.success("Course Published")
  }

  // const handleAddFreePlan = async () => {
  //   if (!course) return
  //   await SchoolPlansService.create({ courseId: course.id, pageState, values: { free: true } })
  // }

  const renderButtonText = () => {
    if (course && course.published) return "Unpublish Course"
    return "Publish Course"
  }

  const renderPublishErrors = () => {
    if (!course) return null
    if (course.published) return null
    if (!course.publish_errors) return null
    if (course.publish_errors.length === 0) return null

    const publishErrors = []
    if (course.publish_errors.includes("Please add a plan")) {
      publishErrors.push(
        <span>
          You have to set a pricing plan before you can publish the course.{" "}
          <Link to={`/admin/courses/${course.id}/information#plan`}>Add a Plan</Link>
          {/* <button onClick={this.handleAddFreePlan}>Add a free plan</button> */}
        </span>,
      )
    }
    return publishErrors.map((pe) => {
      return <p key={pe}>{pe}</p>
    })
  }

  const getLoadingStatus = () => {
    const {
      coursePublishErrors,
      courseUnpublishErrors,
      API_COURSES_PUBLISH_STATUS,
      API_COURSES_UNPUBLISH_STATUS,
    } = pageState

    if (coursePublishErrors && coursePublishErrors.length > 0) return false
    if (courseUnpublishErrors && courseUnpublishErrors.length > 0) return false
    if (API_COURSES_PUBLISH_STATUS === "pending") return true
    if (API_COURSES_UNPUBLISH_STATUS === "pending") return true
    return false
  }

  const getDisabledStatus = () => {
    if (!course) return true
    if (!course.published && !course.publishable) return true
    return false
  }

  return (
    <StyledCourseInformationCard topCard={!!topCard}>
      <div className="form-wrapper" id="information">
        <div className="form-info">
          <h3>Publish</h3>
          <p>Publish or unpublish your course</p>
        </div>
        <div className="form-card">
          {renderPublishErrors()}
          <Button
            type="primary"
            size="large"
            disabled={getDisabledStatus()}
            loading={getLoadingStatus()}
            onClick={handleClick}
          >
            {renderButtonText()}
          </Button>
        </div>
      </div>
    </StyledCourseInformationCard>
  )
}

export default view(InformationPublish)
