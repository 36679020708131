import React, { useEffect } from "react"
import { Redirect } from "react-router-dom"
import { view } from "react-easy-state"
import styled from "styled-components"
import { message } from "antd"

import { Color } from "constant/theme"
import Header from "components/Header/Index"
import CourseCardList from "components/CourseCardList/Index"
import ContentLoading from "components/ContentLoading/Index"
import ContentLoadingError from "components/ContentLoadingError/Index"
import globalStore from "store/index"

import CoursesService from "services/student/courses.service"
import * as AuthHelper from "helpers/auth.helper"

const StyledPage = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.pageColor};
`

const StyledPageContent = styled.div`
  position: relative;
`

const StyledPageContentCoverBackground = styled.div`
  width: 100%;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  & > div {
    min-width: calc(100vw + 100px);
    height: 400px;
    border-radius: 0px 0px 0px 20px;
    background-image: linear-gradient(20deg, ${Color.brandColor} 7%, #2dbde4 93%);
    position: absolute;
    -webkit-tranform-origin: bottom left;
    tranform-origin: bottom left;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
    left: -50px;
    top: -120px;
  }
`

const CoursesIndexPage = () => {
  const pageState = globalStore.ui.student.courses.enrolled

  useEffect(() => {
    const pageState = globalStore.ui.student.courses.enrolled
    const fetchPageData = async () => {
      await CoursesService.enrolled({ pageState })
    }

    fetchPageData()
  }, [])

  const renderPageContent = () => {
    const { courses, API_COURSES_ENROLLED_STATUS } = pageState

    if (!API_COURSES_ENROLLED_STATUS || API_COURSES_ENROLLED_STATUS === "pending") {
      return <ContentLoading />
    }

    if (API_COURSES_ENROLLED_STATUS === "rejected") {
      return <ContentLoadingError />
    }

    if (!courses || courses.length === 0) {
      message.destroy()
      message.info("You haven't enrolled any course yet.")
      return <Redirect to="/courses" />
    }

    return <CourseCardList courses={courses} title="My Courses" enrolled={true} headerColor="white" />
  }

  if (!AuthHelper.isLoggedIn()) return <Redirect to="/courses" />

  return (
    <StyledPage>
      <Header />
      <StyledPageContent>
        <StyledPageContentCoverBackground>
          <div></div>
        </StyledPageContentCoverBackground>
        {renderPageContent()}
      </StyledPageContent>
      {/* <Footer /> */}
    </StyledPage>
  )
}

export default view(CoursesIndexPage)
