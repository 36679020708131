import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import { Radio, Input, Select, Button, message } from "antd"

import SchoolPlansService from "services/admin/school-plans.service"
import globalStore from "store/index"
import StyledCourseInformationCard from "components/common/styled-components/StyledCourseInformationCard"

const StyledPlan = styled.div`
  margin-bottom: 30px;
  & > .ant-radio-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    & > .ant-radio {
      top: 8px;
    }
    & h2 {
      margin: 0px;
    }
    & p {
      color: #a5a5a5;
    }
  }
  & .price {
    margin-left: 26px;
    max-width: 300px;
  }
`

const InformationPricing = ({ match }) => {
  const { courseId } = match.params
  const pageState = globalStore.ui.courses.information
  const {
    course: { plan },
  } = pageState
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [price, setPrice] = useState(0)
  const [currency, setCurrency] = useState("inr")
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (!plan) return
    const parsePrice = parseFloat(plan.price).toFixed(2) || 0

    setSelectedPlan(plan.free ? "free" : "paid")
    setPrice(parsePrice)
    setCurrency(plan.currency)
  }, [plan])

  const handleSubmit = async (e) => {
    e.preventDefault()
    message.destroy()

    if (!["free", "paid"].includes(selectedPlan)) return

    if (selectedPlan === "paid") {
      if (!price) {
        message.error("Price cant't be zero for paid course.")
        return
      }
      if (!currency) {
        message.error("Currency select currency.")
        return
      }
    }

    setSubmitting(true)

    const postData = {
      currency,
      name: "",
      free: selectedPlan === "free" ? true : false,
      price: price,
    }

    if (plan) {
      await SchoolPlansService.update({ pageState, planId: plan.id, values: postData })
    } else {
      await SchoolPlansService.create({ pageState, courseId, values: postData })
    }

    setSubmitting(false)

    if (["pending", "rejected"].includes(pageState.API_SCHOOL_PLANS_CREATE_STATUS)) {
      message.destroy()
      message.error("Failed to update course plan")
      return
    }

    message.destroy()
    message.success("Course details updated successfully.")
  }

  const handleOnChange = ({ checked, plan }) => {
    if (!checked) {
      setSelectedPlan(null)
      return
    }
    setSelectedPlan(plan)
  }

  const handlePriceChange = (e) => {
    // const parsedPrice = parseFloat(e.target.value).toFixed(2)

    // if (!parsedPrice) {
    //   setPrice(null)
    //   return
    // }

    setPrice(e.target.value)
  }

  const renderSubmitButtonText = () => {
    // console.log("pageState -plan", pageState)
    if (plan) {
      return submitting ? "Updating..." : "Update"
    }

    return submitting ? "Saving..." : "Save"
  }

  const currencyDropdown = (
    <Select defaultValue="inr" style={{ width: 80 }}>
      <Select.Option value="inr">INR</Select.Option>
    </Select>
  )

  return (
    <StyledCourseInformationCard>
      <section className="form-wrapper" id="plan">
        <div className="form-info">
          <h3>Pricing</h3>
          <p>Change pricing of course. You can make it free or paid.</p>
        </div>
        <div className="form-card">
          <StyledPlan>
            <Radio
              checked={selectedPlan === "free"}
              onChange={(e) => handleOnChange({ checked: e.target.checked, plan: "free" })}
            >
              <div>
                <h2>Free</h2>
                <p>Allow access to your content free of charge.</p>
              </div>
            </Radio>
          </StyledPlan>
          <StyledPlan>
            <Radio
              checked={selectedPlan === "paid"}
              onChange={(e) => handleOnChange({ checked: e.target.checked, plan: "paid" })}
            >
              <div>
                <h2>One-Time Purchase</h2>
                <p>Allow access to your content after paying fee.</p>
              </div>
            </Radio>
            {selectedPlan === "paid" && (
              <div className="price">
                <h4>Price</h4>
                <Input value={price} addonBefore={currencyDropdown} placeholder="Price" onChange={handlePriceChange} />
              </div>
            )}
          </StyledPlan>
          <div>
            <Button type="primary" size="large" loading={submitting} onClick={handleSubmit}>
              {renderSubmitButtonText()}
            </Button>
          </div>
        </div>
      </section>
    </StyledCourseInformationCard>
  )
}

export default withRouter(InformationPricing)
