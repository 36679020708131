
import styled from "styled-components"

import { BoxShadow } from "constant/theme"

const StyledMainContent = styled.div`
  position: relative;
  width: calc(100vw - 380px); //Total padding(20*3) + Sider width(280)
  left: 360px;
  top: 20px;
  & > div.container {
    background-color: ${props => props.containerBgColor ? props.containerBgColor : null };
    padding: ${props => props.containerBgColor ? "20px" : "0px" };
    width: 100%;
    position: absolute;
    top: ${props => props.containerTop ? props.containerTop : "60px"};
    min-height: calc(100vh - 100px);
    box-shadow: ${props => props.containerBgColor ? BoxShadow.card.normal : null };
    border-radius: 7px;
  }
`

export default StyledMainContent
