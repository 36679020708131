import React, { useState } from "react"
import { view } from "react-easy-state"
import Uppy from "@uppy/core"
import { DashboardModal } from "@uppy/react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { message } from "antd"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"

import VideoLectureContent from "components/pages/courses/VideoLectureContent"
import YoutubeVideoLectureContent from "components/pages/courses/YoutubeVideoLectureContent"
import RichTextLectureContent from "components/pages/courses/RichTextLectureContent"
import CodeLectureContent from "components/pages/courses/CodeLectureContent"
import FileLectureContent from "components/pages/courses/FileLectureContent"

import globalStore from "store/index"
import ContentsService from "services/admin/contents.service"

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: 20,
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#fdf8e9" : "#f5f5f5",
})

const LectureContents = () => {
  const pageState = globalStore.ui.lectures.show
  const { lecture } = pageState
  const { contents } = lecture

  const [uppyModalOpen, setUppyModalOpen] = useState(false)
  const [activeContentId, setActiveContentId] = useState(null)

  const uppy = Uppy({
    meta: { type: "thubmnail" },
    restrictions: { maxNumberOfFiles: 1 },
    autoProceed: false,
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = list.concat().sort((c1, c2) => c1.position - c2.position)

    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    result.forEach((element, index) => (element.position = index + 1))
    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) return
    if (!lecture) return

    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    if (result.type === "contents") {
      const contents = reorder(lecture.contents, sourceIndex, destIndex)
      lecture.contents = contents

      const contentId = result.draggableId
      const position = result.destination.index + 1

      updateContentOrder({ position, contentId })

      return
    }
  }

  const updateContentOrder = async ({ position, contentId }) => {
    if (!lecture) return

    await ContentsService.reorder({ pageState, contentId, values: { position } })
  }

  const handleContentDelete = async (contentId) => {

    setActiveContentId(contentId)

    await ContentsService.delete({ pageState, contentId })

    setActiveContentId(null)

    message.destroy()
    message.success("Deleted successfully.")
  }

  if (!lecture) return null
  if (!contents || contents.length === 0) return null

  const contentsInOrder = contents.concat().sort((c1, c2) => c1.position - c2.position)

  return (
    <div className="new-lecture assets-wrapper">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="contents">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className="asset-droppable-box"
            >
              {contentsInOrder.map((content, index) => (
                <div key={content.id} className="asset-draggable">
                  <Draggable draggableId={content.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div {...provided.dragHandleProps}>
                          {content.content_type === "video" && content.details.wistia_response && (
                            <VideoLectureContent
                              deleting={activeContentId === content.id}
                              embedCode={content.details.wistia_response.html}
                              onUppyModalOpen={() => setUppyModalOpen(true)}
                              onDelete={() => handleContentDelete(content.id)}
                            />
                          )}
                          {content.content_type === "video" && content.details.youtube_url && (
                            <YoutubeVideoLectureContent
                              deleting={activeContentId === content.id}
                              url={content.details.youtube_url}
                              onDelete={() => handleContentDelete(content.id)}
                            />
                          )}
                          {content.content_type === "note" && (
                            <RichTextLectureContent
                              deleting={activeContentId === content.id}
                              content={content.details}
                              onDelete={() => handleContentDelete(content.id)}
                            />
                          )}
                          {content.content_type === "code" && (
                            <CodeLectureContent
                              deleting={activeContentId === content.id}
                              content={content.details}
                              onDelete={() => handleContentDelete(content.id)}
                            />
                          )}
                          {content.content_type === "file" && (
                            <FileLectureContent
                              deleting={activeContentId === content.id}
                              attachedFile={content.attached_file}
                              onDelete={() => handleContentDelete(content.id)}
                            />
                          )}
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Draggable>
                </div>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutside
        open={uppyModalOpen}
        onRequestClose={() => setUppyModalOpen(false)}
      />
    </div>
  )
}

export default view(LectureContents)
