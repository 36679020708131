import React from "react"
// import { Icon } from "antd"

// const InformationPreview = (props) => (
//   <div className="form-wrapper">
//     <div className="form-info">
//       <h3>TODO Preview</h3>
//       <p>See what your course looks like to visitors or enrolled students.</p>
//     </div>
//     <div className="form-card information-preview">
//       <div>
//         <Icon type="line-chart" />
//         <h4>Sales Page</h4>
//         <p>as a visitor</p>
//       </div>
//       <div>
//         <Icon type="ordered-list" />
//         <h4>Course Curriculum</h4>
//         <p>as an enrolled student</p>
//       </div>
//     </div>
//   </div>
// )

const InformationPreviewEmpty = () => <React.Fragment></React.Fragment>

export default InformationPreviewEmpty
