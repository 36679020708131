import { API, generic } from "api"

export default class CoursesService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_COURSES_INDEX_STATUS",
      stateDataKey: ["courses"],
      stateErrorKey: ["coursesErrors"],
      apiEndpoint: API.students.courses.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Courses could not be fetched",
    })
  }

  static show = async ({ pageState, courseId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_COURSES_SHOW_STATUS",
      stateDataKey: ["course", "next_lecture"],
      stateErrorKey: ["courseErrors", "next_lectureErrors"],
      apiEndpoint: API.students.courses.show,
      apiUrlReplacements: { courseId },
      apiData: {},
      errorMessage: "Course could not be fetched",
    })
  }

  static enrolled = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_COURSES_ENROLLED_STATUS",
      stateDataKey: ["courses"],
      stateErrorKey: ["coursesErrors"],
      apiEndpoint: API.students.courses.enrolled,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Courses could not be fetched",
    })
  }
}
