import React, { useEffect } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Col, Button } from "antd"

// import Header from "components/common/layouts/Header"
// import Footer from "components/common/layouts/Footer"
import CourseList from "components/pages/courses/CourseList"
import ContentLoader from "components/common/ContentLoader"
import NewCourseButton from "components/common/NewCourseButton"
import globalStore from "store"

import CoursesService from "services/admin/courses.service"
import StyledMainContent from "components/common/styled-components/StyledMainContent"
import StyledLink from "components/common/styled-components/StyledLink"


const CoursesPage  = ({ history }) => {
  const pageState = globalStore.ui.courses.index
  const { courses, API_COURSES_INDEX_STATUS } = pageState

  useEffect(() => {
    const fetchData = async () => {
      await CoursesService.index({ pageState })
    }

    fetchData()
  }, [pageState])

  const renderPageContent = () => {
    if (!API_COURSES_INDEX_STATUS) return <ContentLoader />
    if (API_COURSES_INDEX_STATUS === "pending") return <ContentLoader />
    if (API_COURSES_INDEX_STATUS === "rejected") return <ContentLoader />

    return <CourseList courses={courses} />
  }

  return (
    <StyledMainContent containerBgColor="white">
      <Row justify="space-between">
        <Col>
          <StyledLink>
            <Button type="link" onClick={() => history.push("/")}>
              <ArrowLeftOutlined /> Go to Home
            </Button>
          </StyledLink>
        </Col>
        <Col>
          <NewCourseButton />
        </Col>
      </Row>
      <div className="container">{renderPageContent()}</div>
    </StyledMainContent>
  )

}


export default withRouter(view(CoursesPage))
