import React from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import { Input, Button, message, Form } from "antd"

import globalStore from "store/index"
import SectionsService from "services/admin/sections.service"

import * as URLHelper from "helpers/url.helper"

const NewSectionForm = ({ history }) => {
  const courseId = URLHelper.getAdminCourseId()
  const pageState = globalStore.ui.courses.curriculum
  const buttonLoading = pageState.API_SECTIONS_CREATE_STATUS === "pending"

  const handleFinish = async (values) => {
    await SectionsService.create({ pageState, courseId, values })

    if (pageState.API_SECTIONS_CREATE_STATUS === "fulfilled") {
      message.success("New Section has been created")
      history.push(`/admin/courses/${courseId}/curriculum`)
    }
  }

  return (
    <Form layout="vertical" onFinish={handleFinish} className="new-section-form">
      <Form.Item label="Section Name" name="name" rules={[{ required: true, message: "Please enter section name!" }]}>
        <Input size="large" placeholder="e.g. Introduction to this course" />
      </Form.Item>
      <div className="align-right">
        <Button size="large" type="primary" htmlType="submit" loading={buttonLoading}>
          {buttonLoading ? "Creating section..." : "Create section"}
        </Button>
      </div>
    </Form>
  )
}

export default withRouter(view(NewSectionForm))
