import { API, generic } from "api"

export default class EnrollmentsService {
  static index = async ({ pageState }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ENROLLMENTS_INDEX_STATUS",
      stateDataKey: ["courses"],
      stateErrorKey: ["coursesErrors"],
      apiEndpoint: API.students.enrollments.index,
      apiUrlReplacements: {},
      apiData: {},
      errorMessage: "Courses could not be fetched",
    })
  }

  static create = async ({ pageState, courseId }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ENROLLMENTS_CREATE_STATUS",
      stateDataKey: ["course"],
      stateErrorKey: ["courseErrors"],
      apiEndpoint: API.students.enrollments.create,
      apiUrlReplacements: { courseId },
      apiData: {},
      errorMessage: "Failed to enroll",
    })
  }

  static razorpayPaymentVerification = async ({ pageState, courseId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_ENROLLMENTS_RAZORPAY_PAYMENT_VERIFICATION_STATUS",
      stateDataKey: ["course"],
      stateErrorKey: ["courseErrors"],
      apiEndpoint: API.students.enrollments.razorpay_payment_verification,
      apiUrlReplacements: { courseId },
      apiData: { ...values },
      errorMessage: "Failed to verify payment.",
    })
  }
}
