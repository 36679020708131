import React, { useState } from "react"
import { view } from "react-easy-state"
import { Droppable, Draggable } from "react-beautiful-dnd"
import DragIcon from "utils/antd/icons/DragIcon"
import { Button } from "antd"
import { withRouter } from "react-router-dom"

import CurriculumTextInlineEdit from "./CurriculumTextInlineEdit"
import CurriculumLectureView from "./CurriculumLectureView"
import globalStore from "store/index"
import LecturesService from "services/admin/lectures.service"

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: 20,
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "#fdf8e9" : "#f5f5f5",
})

const CurriculumSectionView = ({ sections, history }) => {
  const pageState = globalStore.ui.courses.curriculum
  const { API_LECTURES_CREATE_STATUS } = pageState
  const newLectureButtonLoading = API_LECTURES_CREATE_STATUS === "pending"

  const sectionsInOrder = sections.concat().sort((s1, s2) => s1.position - s2.position)

  const [newLectureSectionId, setNewLectureSectionId] = useState(null)

  const handleNewLectureButtonClick = async (sectionId) => {
    const { course } = pageState
    if (!course) return

    const courseId = course.id

    setNewLectureSectionId(sectionId)

    await LecturesService.create({ sectionId, pageState })

    if (!pageState.lecture) return
    const lectureId = pageState.lecture.id

    setNewLectureSectionId(null)

    history.push(`/admin/courses/${courseId}/curriculum/lectures/${lectureId}`)
  }

  return (
    <Droppable droppableId="droppable" type="section">
      {(provided, snapshot) => (
        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} className="section-droppable-box">
          {sectionsInOrder.map((section, index) => (
            <div key={`key-section-${section.id}`} className="section-draggable">
              <Draggable draggableId={`draggable-section-${section.id}`} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <div className="course-section__wrapper">
                      <div className="course-section">
                        <div className="course-section__left">
                          <DragIcon {...provided.dragHandleProps} />
                          <CurriculumTextInlineEdit
                            resource={section}
                            pageState={pageState}
                            text={section.name}
                            title={<h3>{section.name}</h3>}
                          />
                        </div>
                        <div className="course-section__right">
                          <Button
                            type="primary"
                            onClick={() => handleNewLectureButtonClick(section.id)}
                            loading={
                              newLectureButtonLoading && newLectureSectionId && newLectureSectionId === section.id
                            }
                          >
                            New Lecture
                          </Button>
                        </div>
                      </div>
                      <CurriculumLectureView section={section} type={section.id} />
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            </div>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default withRouter(view(CurriculumSectionView))
