import React, { useState } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { Droppable, Draggable } from "react-beautiful-dnd"
import { EditFilled, LoadingOutlined, CheckCircleOutlined } from "@ant-design/icons"
import DragIcon from "utils/antd/icons/DragIcon"
import { Button, message, Tooltip } from "antd"

import CurriculumTextInlineEdit from "./CurriculumTextInlineEdit"
import LecturesService from "services/admin/lectures.service"
import globalStore from "store/index"

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#eaffd2" : "white",
  minHeight: 50,
  position: "relative",
})

const noLectureStyle = (isDraggingOver) => ({
  position: "absolute",
  display: isDraggingOver ? "none" : "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: 50,
  color: "#adadad",
})

const StyledPublishIcon = styled.span`
  color: ${(props) => (props.published ? "green" : "gray")};
  & > i {
    cursor: pointer;
  }
`

const CurriculumLectureView = ({ history, section, type }) => {
  const pageState = globalStore.ui.courses.curriculum

  const [publishLectureId, setPublishLectureId] = useState(null)

  const handlePublishLecture = async ({ lectureId, published }) => {
    const pageState = {}

    console.log("handlePublishButton pageState", pageState)

    setPublishLectureId(lectureId)

    if (published) {
      await LecturesService.unpublish({ pageState, lectureId })
    } else {
      await LecturesService.publish({ pageState, lectureId })
    }

    setPublishLectureId(null)

    const { lecture } = pageState

    message.destroy()

    if (!lecture) {
      message.error(`Failed to ${published ? "unpublished" : "published"} this lecture`)
      return
    }

    const filteredLectures = section.lectures.filter((l) => l.id !== lectureId)

    const modifiedSection = {
      ...section,
      lectures: [...filteredLectures, lecture],
    }

    const sections = globalStore.ui.courses.curriculum.course.sections

    const filteredSections = sections.filter((s) => s.id !== section.id)

    console.log("globalStore.ui.courses.curriculum.course.sections", globalStore.ui.courses.curriculum.course.sections)
    globalStore.ui.courses.curriculum.course.sections = [...filteredSections, modifiedSection]
    console.log("[...filteredSections, modifiedSection]", [...filteredSections, modifiedSection])

    message.success(`Lecture ${published ? "unpublished" : "published"} successfully`)
    return
  }

  const renderPublishButton = ({ lecture }) => {
    if (publishLectureId === lecture.id)
      return (
        <StyledPublishIcon>
          <LoadingOutlined />
        </StyledPublishIcon>
      )

    return (
      <Tooltip title={lecture.published ? "Unpublish lecture" : "Publish lecture"}>
        <StyledPublishIcon published={lecture.published}>
          <CheckCircleOutlined
            onClick={() => handlePublishLecture({ lectureId: lecture.id, published: lecture.published })}
          />
        </StyledPublishIcon>
      </Tooltip>
    )
  }

  const renderLectures = ({ snapshot }) => {
    const { course } = pageState
    // Somehow, if we remove the next line, checkboxes do not work properly
    // const sectionLectures = selectedLectures && selectedLectures[section.id]
    // console.log("selectedLectures[section.id]", sectionLectures)

    if (section.lectures.length === 0) {
      return (
        <div style={noLectureStyle(snapshot.isDraggingOver)}>
          <em>Empty Section. Add a Lecture</em>
        </div>
      )
    }

    const lecturesInOrder = section.lectures.concat().sort((l1, l2) => l1.position - l2.position)

    return lecturesInOrder.map((lecture, index) => (
      <Draggable key={`key-lecture-${lecture.id}`} draggableId={`draggable-lecture-${lecture.id}`} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <div className={snapshot.isDragging ? "course-lecture course-lecture-dragging" : "course-lecture"}>
              <div className="course-lecture__left">
                <DragIcon style={{ color: "gray" }} {...provided.dragHandleProps} />
                {/* <Checkbox
                  checked={sectionLectures && sectionLectures.includes(lecture.id)}
                  onChange={(event) => this.handleCheckboxChange(lecture.id, event)}
                /> */}
                <CurriculumTextInlineEdit
                  resource={lecture}
                  pageState={pageState}
                  text={lecture.name}
                  tag={
                    !lecture.published && {
                      text: "Draft",
                      color: "#c7c7c7",
                    }
                  }
                  title={<Button type="link">{lecture.name}</Button>}
                />
              </div>
              <div className="course-lecture__right">
                <Tooltip title="Edit lecture">
                  <EditFilled
                    onClick={() => history.push(`/admin/courses/${course.id}/curriculum/lectures/${lecture.id}`)}
                  />
                </Tooltip>
                {renderPublishButton({ lecture })}
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    ))
  }

  return (
    <Droppable droppableId={type} type="lecture">
      {(provided, snapshot) => (
        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
          {renderLectures({ snapshot })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default withRouter(view(CurriculumLectureView))
