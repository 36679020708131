import { API, generic } from "api"

export default class SectionsService {
  static create = async ({ pageState, courseId, values }) => {
    if (!courseId) return
    await generic({
      pageState,
      stateApiStatusKey: "API_SECTIONS_CREATE_STATUS",
      // We use 'course' here so that we can update the course in the pageState of curriculum
      stateDataKey: "course",
      stateErrorKey: "createSectionErrors",
      apiEndpoint: API.admin.sections.create,
      apiUrlReplacements: { courseId },
      apiData: { section: values },
      errorMessage: "Section could not be created",
    })
  }

  static update = async ({ pageState, sectionId, values }) => {
    if (!sectionId) return
    await generic({
      pageState,
      stateApiStatusKey: "API_SECTIONS_UPDATE_STATUS",
      // We use 'course' here so that we can update the course in the pageState of curriculum
      stateDataKey: "course",
      stateErrorKey: `updateSectionErrors-${sectionId}`,
      apiEndpoint: API.admin.sections.update,
      apiUrlReplacements: { sectionId },
      apiData: { section: values },
      errorMessage: "Section could not be updated",
    })
  }

  static reorder = async ({ sectionId, pageState, stateApiStatusKey = "API_SECTIONS_REORDER_STATUS", values }) => {
    await generic({
      pageState,
      stateApiStatusKey,
      stateDataKey: "course",
      stateErrorKey: "courseErrors",
      apiEndpoint: API.admin.sections.reorder,
      apiUrlReplacements: { sectionId },
      apiData: { ...values },
      errorMessage: "Course could not be fetched.",
    })
  }
}
