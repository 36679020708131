import React from "react"
import { withRouter } from "react-router-dom"
import { view } from "react-easy-state"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Col, Button } from "antd"

import StyledCourseInformationCard from "components/common/styled-components/StyledCourseInformationCard"
import StyledMainContent from "components/common/styled-components/StyledMainContent"
import StyledLink from "components/common/styled-components/StyledLink"
import NewCourseForm from "components/pages/courses/NewCourseForm"

const NewCoursePage = ({ history }) => {
  return (
    <StyledMainContent>
      <Row justify="space-between">
        <Col>
          <StyledLink>
            <Button type="link" onClick={() => history.push(`/admin/courses`)}>
              <ArrowLeftOutlined /> Go to Curriculum
            </Button>
          </StyledLink>
        </Col>
        <Col></Col>
      </Row>
      <div className="container">
        <StyledCourseInformationCard topCard={true}>
          <div className="form-wrapper">
            <div className="form-info">
              <h3>Information</h3>
              <p>Add basic information about the course {false && "and author name"}</p>
            </div>
            <div className="form-card">
              <NewCourseForm />
            </div>
          </div>
        </StyledCourseInformationCard>
      </div>
    </StyledMainContent>
  )
}

export default withRouter(view(NewCoursePage))
