import { API, generic } from "api"

export default class lectureCommentsServices {
  static index = async ({ pageState, lectureId, nextPage }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_LECTURE_COMMENTS_INDEX_STATUS",
      stateDataKey: ["lecture_comments", "lecture_comments_meta"],
      stateErrorKey: ["lectureCommentsErrors", "lectureCommentsMetaErrors"],
      apiEndpoint: API.students.lecture_comments.index,
      apiUrlReplacements: { lectureId, nextPage },
      apiData: {},
      errorMessage: "Comments could not be updated",
    })
  }

  static create = async ({ pageState, lectureId, values }) => {
    await generic({
      pageState,
      stateApiStatusKey: "API_LECTURE_COMMENTS_CREATE_STATUS",
      stateDataKey: ["lecture_comments"],
      stateErrorKey: ["lectureCommentsErrors"],
      apiEndpoint: API.students.lecture_comments.create,
      apiUrlReplacements: { lectureId },
      apiData: { ...values },
      errorMessage: "Comments could not be created",
    })
  }
}
