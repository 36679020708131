import React, { useEffect } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import { DragDropContext } from "react-beautiful-dnd"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Col, Button, Empty } from "antd"

// import Header from "components/common/layouts/Header"
// import Footer from "components/common/layouts/Footer"
import CurriculumSectionView from "components/pages/courses/CurriculumSectionView"
import ContentLoader from "components/common/ContentLoader"
import globalStore from "store/index"
import ButtonNewSection from "components/common/ButtonNewSection"
import StyledMainContent from "components/common/styled-components/StyledMainContent"
import StyledLink from "components/common/styled-components/StyledLink"

import CoursesService from "services/admin/courses.service"
import SectionsService from "services/admin/sections.service"
import LecturesService from "services/admin/lectures.service"

import * as URLHelper from "helpers/url.helper"
import ContentLoadingError from "components/ContentLoadingError/Index"

const reorder = (list, startIndex, endIndex) => {
  const result = list.concat().sort((s1, s2) => s1.position - s2.position)

  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  result.forEach((element, index) => (element.position = index + 1))
  return result
}

const UnreactivePageContent = () => {
  const pageState = globalStore.ui.courses.curriculum
  const { course, API_COURSES_CURRICULUM_STATUS } = pageState

  const onDragEnd = (result) => {
    if (!result.destination) return
    if (!course) return

    const sourceIndex = result.source.index
    const destIndex = result.destination.index

    if (result.type === "section") {
      const sections = reorder(course.sections, sourceIndex, destIndex)
      course.sections = sections

      const sectionId = result.draggableId.split("-")[2]
      const position = result.destination.index + 1

      updateSectionOrder({ position, sectionId })

      return
    }

    if (result.type === "lecture") {
      const lectureMap = course.sections.reduce((acc, section) => {
        acc[section.id] = section.lectures
        return acc
      }, {})

      const sourceParentId = parseInt(result.source.droppableId)
      const destParentId = parseInt(result.destination.droppableId)

      const sourceLectures = lectureMap[sourceParentId]
      const destLectures = lectureMap[destParentId]

      const lectureId = result.draggableId.split("-")[2]
      const sectionId = result.destination.droppableId
      const position = result.destination.index + 1

      let newSections = [...course.sections]

      /** In this case lectures are reordered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedLectures = reorder(sourceLectures, sourceIndex, destIndex)
        newSections = newSections.map((section) => {
          if (section.id === sourceParentId) {
            section.lectures = reorderedLectures
          }
          return section
        })
        course.sections = newSections
        updateLectureOrder({ lectureId, sectionId, position })
        return
      }

      const newSourceLectures = sourceLectures.concat().sort((s1, s2) => s1.position - s2.position)
      const [draggedItem] = newSourceLectures.splice(sourceIndex, 1)
      newSourceLectures.forEach((element, index) => (element.position = index + 1))

      const newDestLectures = destLectures.concat().sort((s1, s2) => s1.position - s2.position)
      newDestLectures.splice(destIndex, 0, draggedItem)
      newDestLectures.forEach((element, index) => (element.position = index + 1))

      newSections = newSections.map((section) => {
        if (section.id === sourceParentId) {
          section.lectures = newSourceLectures
        } else if (section.id === destParentId) {
          section.lectures = newDestLectures
        }
        return section
      })
      updateLectureOrder({ lectureId, sectionId, position })
      course.sections = newSections
    }
  }

  const updateSectionOrder = async ({ position, sectionId }) => {
    if (!course) return

    await SectionsService.reorder({ sectionId, pageState, values: { position } })
  }

  const updateLectureOrder = async ({ lectureId, sectionId, position }) => {
    if (!course) return

    await LecturesService.reorder({ lectureId, pageState, values: { position, section_id: sectionId } })
  }

  if (API_COURSES_CURRICULUM_STATUS === "rejected") return <ContentLoadingError />

  if (!course) return <ContentLoader />

  const courseId = URLHelper.getAdminCourseId()

  if (course.id !== courseId) return <ContentLoader />

  const { sections } = course

  if (!sections || sections.length === 0) {
    const emptyContent = <strong>It seems you do not have any sections created</strong>

    return (
      <div style={{ marginTop: 90 }}>
        <Empty description={emptyContent}>
          <ButtonNewSection pageState={pageState} text="Create a New Section" />
        </Empty>
      </div>
    )
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <CurriculumSectionView sections={sections} />
    </DragDropContext>
  )
}

const PageContent = view(UnreactivePageContent)

const CourseCurriculumPage = ({ history }) => {
  const courseId = URLHelper.getAdminCourseId()

  // console.log("CourseCurriculumPage: before: useEffect: courseId:", courseId)
  useEffect(() => {
    // console.log("CourseCurriculumPage: inside: useEffect: courseId:", courseId)
    if (!courseId) return
    // console.log("CourseCurriculumPage: inside(before fetchData): useEffect: courseId:", courseId)
    const fetchPageData = async () => {
      const pageState = globalStore.ui.courses.curriculum
      await CoursesService.curriculum({ courseId, pageState })
      pageState.selectedLectures = {}
    }
    fetchPageData()
  }, [courseId])

  const pageState = globalStore.ui.courses.curriculum

  return (
    <StyledMainContent containerBgColor="white">
      <Row justify="space-between">
        <Col>
          <StyledLink>
            <Button type="link" onClick={() => history.push("/admin/courses")}>
              <ArrowLeftOutlined /> Go to Courses
            </Button>
          </StyledLink>
        </Col>
        <Col>
          <ButtonNewSection pageState={pageState} />
        </Col>
      </Row>
      <div className="container">
        <PageContent />
      </div>
    </StyledMainContent>
  )
}

export default withRouter(view(CourseCurriculumPage))
