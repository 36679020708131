import React, { useState } from "react"
import { view } from "react-easy-state"
import { LoadingOutlined, CheckOutlined } from "@ant-design/icons"
import { Input, Button, Form } from "antd"

import SectionsService from "services/admin/sections.service"

const CurriculumSectionTextInlineEditForm = ({ section, pageState, disableEditMode, text }) => {
  const [loading, setLoading] = useState(false)

  const handleFinish = async (values) => {
    setLoading(true)

    await SectionsService.update({ sectionId: section.id, values, pageState })

    setLoading(false)

    if (pageState.API_SECTIONS_UPDATE_STATUS === "fulfilled") {
      disableEditMode()
    }
  }

  return (
    <Form onFinish={handleFinish} initialValues={{ name: text }}>
      <div className="edit-mode-inside">
        <div>
          <Form.Item name="name" rules={[{ required: true, message: "Please enter section name!" }]}>
            <Input />
          </Form.Item>
        </div>
        <div>
          <Button type="primary" shape="circle" htmlType="submit">
            {loading ? <LoadingOutlined /> : <CheckOutlined />}
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default view(CurriculumSectionTextInlineEditForm)
