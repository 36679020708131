import React, { useState } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import AceEditor from "react-ace"

import { Select, Button, message } from "antd"

import ContentsService from "services/admin/contents.service"

// allowed languages
import "brace/mode/html"
import "brace/mode/css"
import "brace/mode/javascript"
import "brace/mode/ruby"
import "brace/mode/php"
import "brace/mode/json"

// allowed theme
import "brace/theme/monokai"

import globalStore from "store"

const StyledWrapper = styled.div`
  padding: 20px 0px;
  & > button {
    margin-top: 20px;
  }
`

const allowedLanguages = [
  {
    value: "html",
    title: "HTML",
  },
  {
    value: "css",
    title: "CSS",
  },
  {
    value: "javascript",
    title: "JS",
  },
  {
    value: "ruby",
    title: "Ruby",
  },
  {
    value: "php",
    title: "PHP",
  },
  {
    value: "json",
    title: "JSON",
  },
]

const NewLectureAddCode = ({ match }) => {
  const pageState = globalStore.ui.lectures.show

  const { lectureId } = match.params

  const [selectedLanguage, setSelectedLanguage] = useState("html")
  const [editorContent, setEditorContent] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const handleEditorType = (value) => {
    setEditorContent(value)
  }

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language)
  }

  const saveCode = async () => {

    const postData = {
      content: {
        details: {
          code: editorContent.toString(),
          language: selectedLanguage,
        },
        content_type: "code",
      },
    }

    setSubmitting(true)

    await ContentsService.create({ pageState, lectureId, values: postData })

    setSubmitting(false)

    if (pageState.lectureErrors) {
      message.error("Failed to add code, please try again")
      return
    }

    setSelectedLanguage("html")
    setEditorContent("")

    message.success("Code added successfully.")
  }

  return (
    <StyledWrapper>
      <div className="code-editor__toolbar">
        <Select defaultValue={selectedLanguage} onChange={handleLanguageChange}>
          {allowedLanguages.map((l) => (
            <Select.Option key={l.value} value={l.value}>
              {l.title}
            </Select.Option>
          ))}
        </Select>
      </div>
      <AceEditor
        placeholder="Write code here..."
        mode={selectedLanguage}
        theme="monokai"
        onChange={handleEditorType}
        name="code-editor"
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={editorContent}
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
      <div className="save-button">
        <Button type="primary" size="large" onClick={saveCode} loading={submitting}>
          {submitting ? "Saving..." : "Save"}
        </Button>
      </div>
    </StyledWrapper>
  )
}


export default withRouter(view(NewLectureAddCode))
