import React from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Col, Button } from "antd"

// import Header from "components/common/layouts/Header"
// import Footer from "components/common/layouts/Footer"
import NewSectionForm from "components/pages/courses/NewSectionForm"
import StyledMainContent from "components/common/styled-components/StyledMainContent"
import StyledLink from "components/common/styled-components/StyledLink"
import StyledCourseInformationCard from "components/common/styled-components/StyledCourseInformationCard"

const NewSectionPage = ({ history, match }) => {
  const { courseId } = match.params


  const renderForm = () => {
    return (
      <StyledCourseInformationCard topCard={true}>
        <div className="form-wrapper">
          <div className="form-info">
            <h3>New section</h3>
            <p>After creating a new section you can add multiple lectures inside it</p>
          </div>
          <div className="form-card">
            <NewSectionForm />
          </div>
        </div>
      </StyledCourseInformationCard>
    )
  }

  return (
    <StyledMainContent>
      <Row justify="space-between">
        <Col>
          <StyledLink>
            <Button type="link" onClick={() => history.push(`/admin/courses/${courseId}/curriculum`)}>
              <ArrowLeftOutlined /> Go to Curriculum
            </Button>
          </StyledLink>
        </Col>
        <Col>
        </Col>
      </Row>
      <div className="container">
        {renderForm()}
      </div>
    </StyledMainContent>
  )
}

export default withRouter(view(NewSectionPage))
