import React, { useState, useEffect } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { Row, Col, Button, Tabs, Switch, message } from "antd"

// import Footer from "components/common/layouts/Footer"
import CurriculumTextInlineEdit from "components/pages/courses/CurriculumTextInlineEdit"
import NewLectureAddWistiaVideo from "components/pages/courses/NewLectureAddWistiaVideo"
import NewLectureAddYoutubeVideo from "components/pages/courses/NewLectureAddYoutubeVideo"
import NewLectureAddFile from "components/pages/courses/NewLectureAddFile"
import NewLectureAddRichText from "components/pages/courses/NewLectureAddRichText"
import NewLectureAddCode from "components/pages/courses/NewLectureAddCode"
import LectureContents from "components/pages/courses/LectureContents"
import ContentLoader from "components/common/ContentLoader"
import StyledLink from "components/common/styled-components/StyledLink"
import StyledMainContent from "components/common/styled-components/StyledMainContent"

import globalStore from "store/index"
import LecturesService from "services/admin/lectures.service"

const StyledNewLectureHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  background: white;
  padding: 20px;
  box-shadow: 0px 17px 20px 0px #4a4a4a14;
  border-radius: 7px;
  & button[type="submit"] {
    margin-left: 10px;
  }
`

const NewLecturePage = ({ history, match }) => {
  const pageState = globalStore.ui.lectures.show
  const { lecture } = pageState
  const { school } = globalStore.sider
  const { lectureId, courseId } = match.params

  const [publishButtonLoading, setPublishButtonLoading] = useState(false)
  const [unpublishButtonLoading, setUnpublishButtonLoading] = useState(false)

  useEffect(() => {
    const fetchLecture = async () => {
      await LecturesService.show({ pageState, lectureId })
    }
    fetchLecture()

    return () => {
      globalStore.ui.lectures.show = {}
    }
  }, [lectureId, pageState])

  const handleLectureUpdate = (lecture) => {
    message.success("Lecture name updated successfully.")
  }

  const handleAllowCommentsChange = async (checked) => {
    const postData = {
      comments_allowed: checked,
    }

    await LecturesService.update({ pageState, lectureId, values: postData })
  }

  const updateStore = (lecture) => {
    globalStore.ui.lectures.show.lecture = lecture
  }

  const handlePublishLecture = async () => {

    setPublishButtonLoading(true)

    await LecturesService.publish({ pageState, lectureId })

    setPublishButtonLoading(false)

    const { lecture } = pageState

    message.destroy()

    if (!lecture || !lecture.published) {
      message.error("Failed to publish this lecture")
      return
    }

    message.success("Lecture published successfully")
    return
  }

  const handleUnpublishLecture = async () => {
    
    setUnpublishButtonLoading(true)

    await LecturesService.unpublish({ pageState, lectureId })

    setUnpublishButtonLoading(false)

    const { lecture } = pageState

    message.destroy()

    if (!lecture || lecture.published) {
      message.error("Failed to unpublish this lecture")
      return
    }

    message.success("Lecture unpublished successfully")
    return
  }

  if (!lecture) { 
    return (
      <StyledMainContent containerBgColor="white">
        <div className="container"><ContentLoader/></div>
      </StyledMainContent> 
    )
  }

  const headerContent = (
    <StyledNewLectureHeader>
      <CurriculumTextInlineEdit
        resource={lecture}
        pageState={pageState}
        text={lecture.name || "New Lecture"}
        title={<Button type="link">{lecture.name}</Button>}
        onLectureUpdate={handleLectureUpdate}
        // onSubmit={handleLectureNameUpdate}
      />
      {!lecture.published ? (
        <Button type="primary" onClick={handlePublishLecture} loading={publishButtonLoading}>
          Publish
        </Button>
      ) : (
        <Button type="primary" onClick={handleUnpublishLecture} loading={unpublishButtonLoading}>
          Unpublish
        </Button>
      )}
    </StyledNewLectureHeader>
  )

  return (
    <StyledMainContent containerBgColor="white" containerTop="140px">
      <Row justify="space-between">
        <Col>
          <StyledLink>
            <Button type="link" onClick={() => history.push(`/admin/courses/${courseId}/curriculum`)}>
              <ArrowLeftOutlined /> Go to Curriculum
            </Button>
          </StyledLink>
        </Col>
        <Col>
        </Col>
      </Row>
      {headerContent}
      <div className="container">
        <div className="new-lecture tabs-wrapper">
          <Tabs defaultActiveKey="videoTab" onChange={() => {}}>
            <Tabs.TabPane tab="Add Video" key="videoTab">
              {school.can_add_wistia_videos ?  <NewLectureAddWistiaVideo /> : <NewLectureAddYoutubeVideo />}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Add File" key="fileTab">
              <NewLectureAddFile updateStore={updateStore} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Add Text" key="textTab">
              <NewLectureAddRichText />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Add Code" key="codeTab">
              <NewLectureAddCode />
            </Tabs.TabPane>
          </Tabs>
        </div>
        {<LectureContents />}
        <div className="new-lecture comments">
          <h3>Allow Comments</h3>
          <Switch defaultChecked={lecture.comments_allowed} onChange={handleAllowCommentsChange} />
        </div>
      </div>
    </StyledMainContent>
  )
}

export default withRouter(view(NewLecturePage))
