import React, { useEffect } from "react"
import { view } from "react-easy-state"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { message } from "antd"

import ContentsService from "services/admin/contents.service"

import globalStore from "store/index"

const StyledWrapper = styled.div`
  padding: 20px 0px;
  & > button {
    margin-top: 20px;
  }
`

const NewLectureAddWistiaVideo = ({ match }) => {
  const { lectureId } = match.params
  const pageState = globalStore.ui.lectures.show
  window._wapiq = window._wapiq || []
  window._wapiq.push((W) => {
    window.wistiaUploader = new W.Uploader({
      accessToken: process.env.REACT_APP_WISTIA_ACCESS_TOKEN,
      projectId: process.env.REACT_APP_WISTIA_PROJECT_ID,
      button: "wistia_upload_button",
      dropZone: "wistia_upload_drop_zone",
      preview: "wistia_upload_preview",
    })
  })

  useEffect(() => {
    const addWistiaResponse = async (oembedResponse) => {
  
      const postData = {
        content: {
          details: {
            wistia_response: oembedResponse,
          },
          content_type: "video",
        },
      }
  
      await ContentsService.create({ pageState, lectureId, values: postData })
  
      if (pageState.lectureErrors) {
        message.error("Failed to upload video, please try again")
        return
      }
  
      message.success("Video added successfully.")
    }

    const id = setTimeout(() => {
      window.wistiaUploader.bind("uploadembeddable", async (file, media, embedCode, oembedResponse) => {
        addWistiaResponse(oembedResponse)
      })
    }, 1000)

    return () => {
      clearTimeout(id)
      window._wapiq = null
      window.wistiaUploader = null
    }
  }, [lectureId, pageState])

  return (
    <StyledWrapper>
      <div id="wistia_upload_drop_zone" className="wistia_upload_drop_zone" style={{ paddingBottom: 50 }}>
        <img src="//fast.wistia.com/images/upload-icon@2x.png" className="wistia_upload_graphic" alt="Upload Video" />
        <div className="wistia_upload_text">Drag and drop a file or</div>
        <div className="wistia_upload_drop_zone_hover"></div>
        <div id="wistia_upload_button" alt="Upload Video"></div>
      </div>
    </StyledWrapper>
  )

}

export default withRouter(view(NewLectureAddWistiaVideo))
