import React from "react"
import { GiftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Alert } from "antd";
import Header from "components/common/layouts/Header"
import Footer from "components/common/layouts/Footer"

const CourseBundlePage = () => {
  return (
    <div className="page courses-page course-bundle-page">
      <Header content={<h3>Bundle Contents</h3>} />
      <div className="bundle-wrapper">
        <Alert
          message="Information"
          description="Students who buy this course will also gain access to any other courses that you bundle in here. This course will be the 'parent' course of the bundle. If you want your bundle to have a different name, create a new course with that name and use that as the bundle parent."
          type="info"
          icon={<QuestionCircleOutlined />}
          showIcon
        />
        <div className="form-card bundle-box">
          <GiftOutlined />
          <h2>Bundle Your Courses</h2>
          <p>Group multiple courses together into a single bundle that you can sell at once.</p>
        </div>
        <Alert
          message="Learn more about creating a course bundle in the CourseMango Knowledge Base"
          type="error"
          showIcon
          icon={<QuestionCircleOutlined />}
          style={{ maxWidth: "65%", margin: "20px auto" }}
        />
      </div>
      <Footer />
    </div>
  )
}


export default CourseBundlePage
